import React from 'react'
// import ceo from  '../../assets/ceo.jpg'
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { FromLeft } from '../../utils/Animation';
import './ceoMessage.scss'
import Loader from '../../common/Loader';


const CeoMessage = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if(inView) {
      controls.start("visible");
    }
  }, [controls, inView]);


  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["ceoMessageData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/page/detail/ceos-message`)
        .then((res) => res.data),
  });

  if (isFetching) return(
    <Loader />
  );

  if (isLoading) return(
    <Loader />
  );

  if (error) return "An error has occurred: " + error.message;

  return (
    <div className='ceoMessageSectionMainBgWrapper'>
    <div className='ceoMessageSectionMainContainer'>
        <motion.img
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={FromLeft}
        src={data.detail.en.CoverImage} 
        alt="ceo-img"
         />

        <div className='ceoMessageSectionTextContainer'>
            <h3>{data.detail.en.PageTitle}</h3>
            <div style={{textAlign: 'justify', hyphens: 'auto'}} className='ceoMessageCKContent' dangerouslySetInnerHTML={{ __html: data.detail.en.Description }}>

            </div>
        </div>

    </div>
    </div>

  )
}

export default CeoMessage