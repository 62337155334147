import React from "react";
import { useContext } from "react";
import { useState, useRef } from "react";
import { Grid, ListItem } from "@mui/material";
import { Pagination } from "@mui/material";
import Bread from "../../common/Bread";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import AxiosClass from "../../common/AxiosClass";
import { LangContext } from "../../context/LangContext";

import TimeAgo from "../../common/TimeAgo";
import CSR from "../../components/CSR";
import Loader from "../../common/Loader";
import CSRCat from "./cat";

const List = () => {
  const [isPage, setIsPage] = useState(1);
  const items = useRef([]);
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["csrData", isPage],
    queryFn: () =>
      AxiosClass.getApi(`/csr/all/${isPage}`).then((res) => res.data),
  });

  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;

  let breadcrumList;

  if (nep) {
    items.current = data.items.np;

    breadcrumList = {
      image: "",
      link: "/csr",
      title: "कर्पोरेट सामाजिक उत्तरदायित्व",
      l1: "होम",
      l2: "कर्पोरेट सामाजिक उत्तरदायित्व",
      l3: "",
    };
  } else {
    items.current = data.items.en;

    breadcrumList = {
      image: "",
      link: "/csr",
      title: "CSR Activities",
      l1: "Home",
      l2: "CSR",
      l3: "",
    };
  }
  return (
    <>
      <Bread data={breadcrumList}>
        <CSR />

        <CSRCat />
      </Bread>
    </>
  );
};

export default List;
