import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
// import "./companyProfile.scss";
import Loader from "../../common/Loader";
import Bread from "../../common/Bread";
const BankingHours = () => {
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["BankingHoursData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/page/detail/banking-hours`)
        .then((res) => res.data),
  });

  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;
  const breadcrumList = {
    image: "",
    title: "Banking Hours",
    l1: "Home",
    l2: "Banking Hours",
    l3: "",
  };
  return (
    <Bread data={breadcrumList}>
      <div>
        <div
          style={{ textAlign: "justify", hyphens: "auto" }}
          className="companyProfileCKContainer"
          dangerouslySetInnerHTML={{
            __html: data.detail.en.Description
              ? data.detail.en.Description
              : "No data",
          }}
        ></div>
      </div>
    </Bread>
  );
};

export default BankingHours;
