import React,{useState, useMemo} from 'react'
import { useParams } from 'react-router-dom';
import Bread from '../../common/Bread'
import CeoMessage from '../../components/CeoMessage'
import CompanyProfile from '../../components/CompanyProfile';
import './about.scss'

const About = () => {
  const [title, setTitle] = useState("")
  const {slug} = useParams()


  function titleSwitch () {
    switch (slug) {
      case "ceo-message": {
        setTitle("CEO Message")
      }
      break;
      case "company-profile": {
        setTitle("Company Profile")
      }
      break;
      default:{
        setTitle("About Us")
      }
      break;
    }
  }

  var breadMemo = useMemo(titleSwitch, [slug])


  const breadcrumList = {
    image: '',
    title: title,  
    l1: 'Home',
    l2: 'About Us',
    l3: ''  
  }



  return (
    <Bread data={breadcrumList} >
      <div>
        {slug === 'ceo-message'?(
          <CeoMessage />
        ): slug === 'company-profile'?(
           <CompanyProfile />
        ) : (<h1>Not Found</h1>)}
      </div>
    </Bread>
  )
}

export default About