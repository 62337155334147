import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Bread from "../../common/Bread";
import Loader from "../../common/Loader";
import "./fund.css";

const CostFund = () => {
  const breadcrumList = {
    image: "",
    title: "Cost of Fund",
    l1: "Home",
    l2: "Cost of Fund",
    l3: "",
  };

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["ceoMessageData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/page/detail/cost-of-fund`)
        .then((res) => res.data),
  });
  console.log(data);
  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;

  return (
    <Bread data={breadcrumList}>
      <div
        className="CostOfFund"
        dangerouslySetInnerHTML={{ __html: data.detail.en.Description }}
      ></div>
    </Bread>
  );
};

export default CostFund;
