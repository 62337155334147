import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../common/Loader";
import Bread from "../../common/Bread";
import { motion } from "framer-motion";
import play from "../../assets/mobileBank/play.png";
import app from "../../assets/mobileBank/app.png";

const DigitalServicePage = () => {
  const [title, setTitle] = useState("");
  const { slug } = useParams();

  function titleSwitch() {
    switch (slug) {
      case "internet-banking":
        {
          setTitle("Internet Banking");
        }
        break;
      case "mobile-banking":
        {
          setTitle("Mobile Banking");
        }
        break;
      case "sms-banking":
        {
          setTitle("SMS Banking");
        }
        break;
      case "debit-cards":
        {
          setTitle("Debit Cards");
        }
        break;
      case "set-banking-password":
        {
          setTitle("Set Banking Password");
        }
        break;
      case "load-digital-wallet":
        {
          setTitle("Load Digital Wallet");
        }
        break;
      default:
        {
          setTitle("Digital Services");
        }
        break;
    }
  }

  var breadMemo = useMemo(titleSwitch, [slug]);

  const breadcrumList = {
    image: "",
    title: title,
    l1: "Home",
    l2: "Digital Services",
    l3: "",
  };

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["digitalPageData", slug],
    queryFn: () =>
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/digital_services/detail/${slug}`
        )
        .then((res) => res.data),
  });

  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;

  return (
    <Bread data={breadcrumList}>
      <div
        dangerouslySetInnerHTML={{ __html: data.detail.description }}
        className="services-ck-content"
      ></div>

      {data.detail.external_url && (
        <a
          className="digital-external-button"
          href={data.detail.external_url}
          target="_blank"
        >
          <p>Redirect Link</p>
        </a>
      )}

      {slug == "mobile-banking" && (
        <h3 className="mobile-bank-page-title">
          Download <span>ICFC Finance Smart</span> App From
        </h3>
      )}

      {slug == "mobile-banking" && (
        <div className="app-logo-container">
          <a
            href="https://apps.apple.com/us/app/icfc-finance-smart/id1381298632"
            target="_blank"
          >
            <motion.img
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.8 }}
              src={app}
              alt="app-store"
              style={{ cursor: "pointer" }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.f1soft.banksmart.icfcfinance&hl=en&gl=US&pli=1"
            target="_blank"
          >
            <motion.img
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.8 }}
              src={play}
              alt="play-store"
              style={{ cursor: "pointer" }}
            />
          </a>
        </div>
      )}
    </Bread>
  );
};

export default DigitalServicePage;
