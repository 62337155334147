import { BsGlobe } from "react-icons/bs";
import { MdOutlineMail } from "react-icons/md";
import meroshare from "../../assets/navbar/meroshare.png";
import connectips from "../../assets/navbar/connectips.png";
import "./loginDropdown.scss";

const LoginDropdown = () => {
  return (
    <div className="loginDropCompContainer">
      <div className="item">
        <a href="https://www.eicfc.com.np/login.asp" target="_blank">
          <BsGlobe size={20} />
          <p>E-Banking</p>
        </a>
        <div className="underline"></div>

        <a
          href="https://mail.icfcbank.com/owa/auth/logon.aspx?replaceCurrent=1&url=https%3a%2f%2fmail.icfcbank.com%2fowa%2f"
          target="_blank"
        >
          <MdOutlineMail size={22} />
          <p>Email Login</p>
        </a>
        <div className="underline"></div>

        <a href="https://meroshare.cdsc.com.np/#/login" target="_blank">
          <img src={meroshare} alt="mero-share" />
          <p>Mero Share</p>
        </a>
        <div className="underline"></div>

        <a href="https://www.connectips.com/" target="_blank">
          <img src={connectips} alt="connect-ips" />
          <p>Connect IPS</p>
        </a>
      </div>
    </div>
  );
};

export default LoginDropdown;
