import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { LangContext } from "../../context/LangContext";
import "./cat.css";
const CSRCat = () => {
  const [isPage, setIsPage] = useState(1);
  const items = useRef([]);
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;
  const { slug } = useParams();
  const [Data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(
        `https://admin.icfcbank.com/api/csr/csr_type/`
      );
      setData(response.data.items);
      console.log("Main Cat", response.data.items);
    } catch (error) {
      setError(error);
      console.log("er", error);
    }
    setLoading(false);
  }
  if (loading) return "....";
  if (error) return "error";
  return (
    <>
      <div className="csr_cat_box">
        {!nep
          ? Data.en.map((i) => {
              return (
                <>
                  <div className="cat_items" key={i.id}>
                    <div className="cat_image">
                      <img src={i.DocPath ? i.DocPath : ""} />
                    </div>
                    <div className="cat_title">
                      <h1>{i.Title ? i.Title : ""}</h1>
                      {i.id == 5 ? (
                        <a className="CSRBTN" href={`/csr/lists/items/${i.id}`}>
                          Explore
                        </a>
                      ) : (
                        <a className="CSRBTN" href={`/csr/lists/${i.id}`}>
                          Explore
                        </a>
                      )}
                    </div>
                  </div>
                </>
              );
            })
          : Data.np.map((i) => {
              return (
                <>
                  <div className="cat_items">
                    <div className="cat_image">
                      <img src={i.DocPath ? i.DocPath : ""} />
                    </div>
                    <div className="cat_title">
                      <h1>{i.Title ? i.Title : ""}</h1>
                      <a className="CSRBTN" href={`/csr/item/${i.id}`}>
                        Explore
                      </a>
                    </div>
                  </div>
                </>
              );
            })}
      </div>
    </>
  );
};
export default CSRCat;
