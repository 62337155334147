import React, { useState, useMemo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BranchList = ({ data, callbackSelect }) => {
  const [expanded, setExpanded] = React.useState("0");
  const [query, setQuery] = React.useState();
  const [locData, setLocData] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Function to get filtered list
  function getSearchList() {
    setLocData(
      data?.filter(
        (s) =>
          s.PageTitle.toLowerCase().includes(query) ||
          s.Phone.toLowerCase().includes(query)
      )
    );
  }

  var searchList = useMemo(getSearchList, [query]);

  return (
    <div id="customScroll" className="branchesListContainer">
      <div className="branchSearchContainer">
        <label>Search</label>
        <input
          type="text"
          placeholder="Search Branch by Location..."
          name="search"
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
        />
      </div>

      <div className="branchesAccordianContainer">
        {query?.length > 0 ? (
          <>
            {locData?.length > 0 ? (
              <>
                {locData.map((i, index) => (
                  <Accordion
                    key={index}
                    onClick={() =>
                      callbackSelect({
                        id: i.id,
                        lat: i.latitude,
                        lng: i.longitude,
                      })
                    }
                    expanded={expanded === `${index}`}
                    onChange={handleChange(`${index}`)}
                    sx={{
                      "& .MuiPaper-root-MuiAccordion-root.Mui-expanded": {
                        margin: "0px",
                      },
                      "& .MuiPaper-root-MuiAccordion-root": {
                        border: "2px solid red",
                        borderRadius: "10px",
                      },
                      "& .MuiAccordionSummary-root": {
                        height: "20px",
                        backgroundColor: "#fff",
                        color: "#003399",
                        borderRadius: "10px",
                      },
                      "& .MuiAccordionSummary-root.Mui-expanded": {
                        minHeight: "30px",
                        "& .MuiTypography-root": {
                          color: "#dd1134",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#dd1134",
                        },
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "20px",
                          color: "#003399",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        {i.PageTitle}
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div className="branch-accordion-details-container">
                        <p>Tel: {i.Phone}</p>
                        <p>Post Box: {i.POB}</p>
                        <p>Mobile: {i.mobile}</p>
                        <p>Branch Manager: {i.Manager}</p>
                        <p>
                          Email: <span>{i.Email}</span>
                        </p>
                        <p>
                          Googleplus Code: <span>{i.google_plus}</span>
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </>
            ) : (
              <h3>Sorry, No Results Found</h3>
            )}
          </>
        ) : (
          <>
            {data?.map((i, index) => (
              <Accordion
                key={index}
                onClick={() =>
                  callbackSelect({
                    id: i.id,
                    lat: i.latitude,
                    lng: i.longitude,
                  })
                }
                expanded={expanded === `${index}`}
                onChange={handleChange(`${index}`)}
                sx={{
                  "& .MuiPaper-root-MuiAccordion-root.Mui-expanded": {
                    margin: "0px",
                  },
                  "& .MuiPaper-root-MuiAccordion-root": {
                    border: "2px solid red",
                    borderRadius: "10px",
                  },
                  "& .MuiAccordionSummary-root": {
                    height: "20px",
                    backgroundColor: "#fff",
                    color: "#003399",
                    borderRadius: "10px",
                  },
                  "& .MuiAccordionSummary-root.Mui-expanded": {
                    minHeight: "30px",
                    "& .MuiTypography-root": {
                      color: "#dd1134",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#dd1134",
                    },
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "20px",
                      color: "#003399",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {i.PageTitle}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <div className="branch-accordion-details-container">
                    <p>Tel: {i.Phone}</p>
                    <p>Post Box: {i.POB}</p>
                    <p>Mobile: {i.mobile}</p>
                    <p>Branch Manager: {i.Manager}</p>
                    <p>
                      Email: <span>{i.Email}</span>
                    </p>
                    <p>
                      Google Plus Code: <span>{i.google_plus}</span>
                    </p>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default BranchList;
