import React, { useState, useRef } from "react";
import { Grid, ListItem } from "@mui/material";
import { Pagination } from "@mui/material";
import Bread from "../../common/Bread";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import AxiosClass from "../../common/AxiosClass";
import { LangContext } from "../../context/LangContext";
import TimeAgo from "../../common/TimeAgo";
import Loader from "../../common/Loader";
import cover from "../../assets/news.png";

const List = () => {
  const [isPage, setIsPage] = useState(1);
  const items = useRef([]);

  const lang = React.useContext(LangContext);
  const nep = lang.state.np;

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["newsData", isPage],
    queryFn: () =>
      AxiosClass.getApi(`/news/all/${isPage}`).then((res) => res.data),
  });

  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;

  let breadcrumList;

  if (nep) {
    items.current = data.items.np;

    breadcrumList = {
      image: "",
      link: "/news",
      title: "सबै न्युज",
      l1: "होम",
      l2: "न्युज",
      l3: "",
    };
  } else {
    items.current = data.items.en;

    breadcrumList = {
      image: "",
      link: "/news",
      title: "News",
      l1: "Home",
      l2: "News",
      l3: "",
    };
  }

  // const filtered = Object.keys(data.items).filter((key) => key.includes(`${nep ? 'np' : 'en'}`)).reduce((cur, key) => { return Object.assign(cur, { ['items']: data[key] }) }, {});

  // console.log('fil=>', filtered);

  return (
    <>
      <Bread data={breadcrumList}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {items.current.map((item, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <div className="box-item-news">
                <div className="img-news">
                  <img src={cover} alt={item.Title} width={"80%"} />
                </div>
                <div className="content-news">
                  <TimeAgo timestamp={item.created_on} />

                  <h1>
                    <a href={`/news/${item.slug}`}>
                      {item.Title.substring(0, 100)}
                    </a>
                  </h1>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={12} md={12}>
            {data?.total >= data?.per_page && (
              <Pagination
                style={{ marginTop: "24px" }}
                className="paginationContainer"
                count={Math.ceil(data?.total / data?.per_page)}
                color="primary"
                size="large"
                page={isPage}
                onChange={(e, value) => {
                  setIsPage(value);
                  window.scrollTo(0, 0);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Bread>
    </>
  );
};
export default List;
