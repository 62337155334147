import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Bread from '../../common/Bread'
import DepositCalculator from '../../components/DepositCalculator';
import EMICalculator from '../../components/EMICalculator'
import RecurringCalculator from '../../components/RecurringCalculator';
import { LangContext } from '../../context/LangContext';
import './calculators.scss'

const Calculators = () => {
    const breadcrumList = {
        image: '',
        title: 'Calculators',
        l1: 'Home',
        l2: 'Calculators',
        l3: ''  
      }

      const lang = React.useContext(LangContext);
      const np = lang.state.np;

  return (
    <Bread data={breadcrumList} >
    <div className='calculatorPageMainContainer'>
        <Tabs className='tabs' defaultIndex={0}>
          <TabList className='tabList'>

            <Tab key={0} className='button'>{np?'कर्जा':'EMI'}</Tab>
            <Tab key={1} className='button'>{np?'बचत':'Recurring Deposit'}</Tab>
            <Tab key={2} className='button'>{np?'मिश्रित ब्याज':'Compound Interest'}</Tab>
          </TabList>

          <TabPanel key={0}>
              <EMICalculator />    
          </TabPanel>

          <TabPanel key={1}>
              <RecurringCalculator />
          </TabPanel>

          <TabPanel key={2}>
              <DepositCalculator />
          </TabPanel>

        </Tabs>
        
        
    </div>
    </Bread>
  )
}

export default Calculators