// import React from 'react';
import axios from 'axios'
const baseUrl = `${process.env.REACT_APP_BASE_URL}`

// const token = localStorage.getItem("isAuthenticated");
// const config = {
//     headers: {
//         "Authorization": "Bearer " + token,
//     },
// };
class AxiosClass {

    static postApi(url, data) {
        return axios.post(baseUrl + url, data);
    }

    static getApi(url) {
        return axios.get(baseUrl + url);
    }

    static deleteApi(url) {
        return axios.delete(baseUrl + url);
    }

    static putApi(url, data) {
        return axios.put(baseUrl + url, data);
    }

    // auth axios
    // static postApiAuth(url, data) {
    //     return axios.post(baseUrl + url, data, config);
    // }

    // static getApiAuth(url) {
    //     return axios.get(baseUrl + url, config);
    // }

    // static deleteApiAuth(url) {
    //     return axios.delete(baseUrl + url, config);
    // }

    // static putApiAuth(url, data) {
    //     return axios.put(baseUrl + url, data, config);
    // }
}

export default AxiosClass
