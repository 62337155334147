import React from "react";
import { LangContext } from "../../context/LangContext";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
// import { FromBottom } from '../../utils/Animation';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
  Image,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import "./digitalServices.scss";
// import mainImg from '../../assets/digitalService/main.png'
import digi from "../../assets/digitalService/digi.png";
import internet from "../../assets/digitalService/internet.png";
import loan from "../../assets/digitalService/loan.png";
import mobile from "../../assets/digitalService/mobile.png";
import password from "../../assets/digitalService/password.png";
import sms from "../../assets/digitalService/sms.png";
import { useNavigate } from "react-router-dom";
// import img01 from '../../assets/navbar/01.jpg';
// import img02 from '../../assets/navbar/02.jpg';
// import img03 from '../../assets/navbar/03.jpg';
// import img04 from '../../assets/navbar/04.jpg';
// import img05 from '../../assets/navbar/05.jpg';

const DigitalServices = ({ data }) => {
  const navigate = useNavigate();

  const lang = React.useContext(LangContext);
  const np = lang.state.np;
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div className="digitalServicesContainer">
      <div className="digitalFlexContainer">
        <div className="digitalServicesContentContainer">
          <h2>{np ? "डिजिटल बैंकिङ सेवाहरू" : "Digital Banking Services"}</h2>
          <div className="buttons-container">
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.8 }}
              onClick={() => navigate("/services/icfc-ebanking")}
              className="button"
            >
              <img src={internet} alt="internet" />
              <p>{np ? "इन्टरनेट बैंकिङ" : "Internet Banking"}</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.8 }}
              onClick={() => navigate("/services/mobile-banking")}
              className="button"
            >
              <img src={mobile} alt="mobile" />
              <p>{np ? "मोबाइल बैंकिङ" : "Mobile Banking"}</p>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.8 }}
              onClick={() => navigate("/services/mobile-banking")}
              className="button"
            >
              <img src={sms} alt="sms" />
              <p>{np ? "एसएमएस बैंकिङ" : "SMS Banking"}</p>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.8 }}
              onClick={() => navigate("/services/stc-upi-debit-card")}
              className="button"
            >
              <img src={loan} alt="loan" />
              <p>{np ? "डेबिट कार्ड" : "Debit Cards"}</p>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.8 }}
              onClick={() => navigate("/services/inter-bank-transaction")}
              className="button"
            >
              <img src={password} alt="password" />
              <p>{np ? "बैंकिङ पासवर्ड सेट" : "Set Banking Password"}</p>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.8 }}
              onClick={() => navigate("/services/load-digital-wallet")}
              className="button"
            >
              <img src={digi} alt="digi" />
              <p>{np ? "डिजिटल वालेट लोड" : "Load Digital Wallet"}</p>
            </motion.div>
          </div>
        </div>

        <CarouselProvider
          className="digitalImage"
          visibleSlides={1}
          totalSlides={data?.length}
          step={1}
          naturalSlideWidth={1}
          naturalSlideHeight={1}
          hasMasterSpinner
          isPlaying={true}
          interval={10000}
          infinite={true}
        >
          <div className="sliderContainer">
            <Slider>
              {data.map((i, index) => (
                <Slide key={index} index={index}>
                  <Image
                    className="slideImage"
                    src={i.file}
                    alt={`image-${index}`}
                  />
                </Slide>
              ))}
            </Slider>

            <ButtonBack className="cus-back">
              <BsFillArrowLeftCircleFill className="btn-left" />
            </ButtonBack>

            <ButtonNext className="cus-next">
              <BsFillArrowRightCircleFill className="btn-right" />
            </ButtonNext>
          </div>
        </CarouselProvider>
        {/* <motion.img
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={FromBottom}
      className='digitalImage' 
      src={mainImg} 
      alt="digital-image" 
      /> */}
      </div>
    </div>
  );
};

export default DigitalServices;
