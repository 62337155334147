export const FromBottom = {
    visible: { y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.9, type: "tween" } },
    hidden: { y: 20, opacity: 0 },
}

export const FromTop = {
    visible: { y: 0, opacity: 1, transition: { delay: 0.5, duration: 0.9, type: "tween" } },
    hidden: { y: -20, opacity: 0 },
}

export const FromLeft = {
    visible: { x: 0, opacity: 1, transition: { delay: 0.5, duration: 0.9, type: "tween" } },
    hidden: { x: -50, opacity: 0 },
}

export const FromRight = {
    visible: { x: 0, opacity: 1, transition: { delay: 0.5, duration: 0.9, type: "tween" } },
    hidden: { x: 0, opacity: 0 },
}