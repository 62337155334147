import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import App from "./App";
import "./assets/fonts/Amithen.ttf";
import { LangProvider } from "./context/LangContext";

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
// const queryClient = new QueryClient();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: true,
      retry: 5,
      retryDelay: 1000,
      staleTime: 300000,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <LangProvider>
    <QueryClientProvider client={queryClient}>
      <App />
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  </LangProvider>
  // </React.StrictMode>
);
