import React,{useState, useEffect} from 'react'
import { useQuery } from "@tanstack/react-query";
import {IoIosArrowForward} from 'react-icons/io'
import axios from "axios";
import { useParams, useNavigate  } from 'react-router-dom';
import Bread from '../../common/Bread'
import Loader from '../../common/Loader';

const Loan = () => {

    const breadcrumList = {
        image: '',
        title: 'Loan Products',
        l1: 'Home',
        l2: 'Loan Products',
        l3: ''
    }

    const navigate = useNavigate();

    const {slug} = useParams();
  
    const [isActive, setIsActive] = useState(slug)
  
    useEffect(() => { 
      setIsActive(slug)

    },[slug])

    function capFirstLetterInSentence(sentence) {
        let LWords = sentence.toLowerCase();
        let words =  LWords.split(" ").map(word => {
            return word[0]?.toUpperCase() + word.slice(1);
            // str?.toUpperCase() || ''
        })
        return words.join(" ");
    }

      const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ["productsData"],
        queryFn: () =>
          axios
            .get(`${process.env.REACT_APP_BASE_URL}/product/all`)
            .then((res) => res.data),
      });
  
      if (isFetching)
        return(
          <Loader />
        ); 
  
      if (isLoading) return(
        <Loader />
      );
    
      if (error) return "An error has occurred: " + error.message;

  return (
    <Bread data={breadcrumList}>
    <div className='servicePageContainer'>
        <div className='contentContainer'>
        {data.items.en.filter(obj => {return obj.cat_name === 'Loan'}).map((i, index) => (
          <div 
          key={index}
          className='content'
          style={{ display: `${isActive == i.slug?'block':'none'}` }}
          >
            {/* <p 
                dangerouslySetInnerHTML={{ __html: i.Description }}
            ></p> */}

              <h2 className='content-title'># {capFirstLetterInSentence(i.title)}</h2>
              <div className='services-ck-content' dangerouslySetInnerHTML={{ __html: i.Description }}>

             </div>
          </div>
          ))}
        </div>  

        <div className='buttons'>
        <h2>Products</h2>
        {data.items.en.filter(obj => {return obj.cat_name === 'Loan'}).map((i, index) => (
          <div 
          key={i.slug}
          onClick={() => {navigate(`/product/professional-loan/${i.slug}`); window.scrollTo(0, 0);}}
          className='btn'
          style={{ color: `${isActive == i.slug?'#fff':'#0f2137'}`, backgroundColor: `${isActive == i.slug?'#003399':'#fff'}`,
        }}>
            <p>
                {capFirstLetterInSentence(i.title)}
            </p>
            <IoIosArrowForward className='icon' />
          </div>
        ))}

        </div>
    </div>
    </Bread>
  )
}

export default Loan