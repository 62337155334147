import React, { useEffect } from "react";
import HomePageModal from "../HomePageModal";

const PopUpOnce = ({ values }) => {
  const [visible, setVisible] = React.useState(false);
  useEffect(() => {
    let pop_status = localStorage.getItem("pop_status");
    if (!pop_status) {
      setVisible(true);
      localStorage.setItem("pop_status", 1);
    }
  }, []);
  window.onbeforeunload = function () {
    localStorage.removeItem("pop_status");
  };

  if (!visible) return null;

  return (
    <>
      {values?.slice(0, 10).map((i, index) => (
        <HomePageModal key={index} iDocPath={i.file} />
      ))}
    </>
  );
};

export default PopUpOnce;
