import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { AiOutlinePercentage, AiOutlineCalendar } from "react-icons/ai";
import { LangContext } from "../../context/LangContext";

ChartJS.register(ArcElement, Tooltip, Legend);

const SlideMarks = {
  marksTenure: [
    { value: 0, label: "0" },
    { value: 90, label: "90" },
    { value: 180, label: "180" },
    { value: 270, label: "270" },
    { value: 360, label: "360" },
  ],
  marksInt: [
    { value: 0, label: "0" },
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
  ],
  marksAmt: [
    { value: 0, label: "0" },
    { value: 5000000, label: "50L" },
    { value: 10000000, label: "1Cr" },
    { value: 15000000, label: "1.5Cr" },
    { value: 20000000, label: "2Cr" },
  ],
};

const SSlider = styled(Slider)({
  color: "#003399",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
});

const RecurringCalculator = () => {
  const lang = React.useContext(LangContext);
  const np = lang.state.np;

  const [pAmount, setpAmount] = useState(6000);
  const [interest, setInterest] = useState(7);
  const [duration, setDuration] = useState(12);

  // function dCalc () {
  //     switch (selectValue.label) {
  //         case 'Monthly' : {
  //             setDuration()
  //         }
  //     }
  // }

  const maxValue = 20000000;
  const intMax = 20;
  const maxDuration = 360;

  // recurring
  const e = Math.pow(1 + interest / 400, duration / 3);
  const d = Math.pow(1 + interest / 400, -1 / 3);
  const m = ((pAmount * (e - 1)) / (1 - d)).toFixed(2);

  const commaM = Number(m).toLocaleString("en-IN");

  const totalInstallment = pAmount * duration;

  const commaTotalInstallment =
    Number(totalInstallment).toLocaleString("en-IN");

  const interestReceivable = (m - pAmount * duration).toFixed(2);

  const commaInterestReceivable =
    Number(interestReceivable).toLocaleString("en-IN");

  const commapAmount = pAmount.toLocaleString("en-IN");

  const chartData = {
    labels: ["Interest Receivable", "Total Deposit Amount"],
    datasets: [
      {
        label: "",
        data: [interestReceivable, totalInstallment],
        backgroundColor: ["rgba(221, 17, 52, 0.5)", "rgba(0, 51, 153, 0.5)"],
        borderColor: ["rgba(221, 17, 52, 1)", "rgba(0, 51, 153, 1)"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="emiCalculatorMainContainer">
      <div className="emiCalculatorInputMainContainer">
        {/* amount */}
        <div className="emiCalculatorInputFlexItem">
          <div className="emiCalculatorAboveSlider">
            <div className="emiCalculatorSliderTitleContainer">
              <h3 className="emiCalculatorSliderTitleText">
                {np ? "निक्षेप रकम" : "Deposit Amount"}
              </h3>
              <p className="emiCalculatorSliderTitleSubText">
                {np ? "रुपैयाँमा" : "in rupees"}
              </p>
            </div>
            <div className="emiCalculatorAmountInputContainer">
              <input
                className="emiCalculatorAmountInput"
                id="loanAmount"
                value={pAmount}
                type="number"
                onChange={(e) => setpAmount(e.target.value)}
              />
              <div className="emiCalculatorAmountInputButton">
                {np ? "रु" : "Rs"}
              </div>
            </div>
          </div>

          <SSlider
            value={pAmount}
            marks={SlideMarks.marksAmt}
            onChange={(event, vAmt) => {
              setpAmount(vAmt);
            }}
            defaultValue={pAmount}
            max={maxValue}
            valueLabelDisplay="auto"
            aria-label="pretto slider"
          />
        </div>

        {/* interest */}
        <div className="emiCalculatorInputFlexItem">
          <div className="emiCalculatorAboveSlider">
            <div className="emiCalculatorSliderTitleContainer">
              <h3 className="emiCalculatorSliderTitleText">
                {np ? "ब्याजदर" : "Interest Rate"}
              </h3>
              <p className="emiCalculatorSliderTitleSubText">
                {np ? "*प्रतिशत" : "*percent"}
              </p>
            </div>
            <div className="emiCalculatorAmountInputContainer">
              <input
                className="emiCalculatorAmountInput"
                id="loanAmount"
                value={interest}
                type="number"
                onChange={(e) => setInterest(e.target.value)}
              />
              <div className="emiCalculatorAmountInputButton">
                <AiOutlinePercentage />
              </div>
            </div>
          </div>

          <SSlider
            value={interest}
            marks={SlideMarks.marksInt}
            onChange={(event, vInt) => {
              setInterest(vInt);
            }}
            defaultValue={pAmount}
            max={intMax}
            valueLabelDisplay="auto"
            aria-label="pretto slider"
          />
        </div>

        {/* duration */}
        <div className="emiCalculatorInputFlexItem">
          <div className="emiCalculatorAboveSlider">
            <div className="emiCalculatorSliderTitleContainer">
              <h3 className="emiCalculatorSliderTitleText">
                {np ? "निक्षेप अवधि" : "Deposit Tenure"}
              </h3>
              <p className="emiCalculatorSliderTitleSubText">
                {/* {np ? "वर्षहरूमा" : "in years"} */}
                in months
              </p>
            </div>
            <div className="emiCalculatorAmountInputContainer">
              <input
                className="emiCalculatorAmountInput"
                id="loanAmount"
                value={duration}
                type="number"
                onChange={(e) => setDuration(e.target.value)}
              />
              <div className="emiCalculatorAmountInputButton">
                <AiOutlineCalendar />
              </div>
            </div>
          </div>

          <SSlider
            value={duration}
            marks={SlideMarks.marksTenure}
            onChange={(event, vDur) => {
              setDuration(vDur);
            }}
            defaultValue={duration}
            max={maxDuration}
            valueLabelDisplay="auto"
            aria-label="pretto slider"
          />
        </div>
      </div>

      <div className="emiCalculatorResultContainer">
        {/* <h4 className='emiCalculatorResultTitle'>{np?'मासिक किस्ता':'Monthly EMI'}</h4>
        <h4 className='emiCalculatorResultMonthlyEMI'
        >{np?'रु.':'Rs.'} {commaEMI}</h4> */}

        <div className="emiCalculatorResultRowContainer">
          <h4 className="emiCalculatorResultTitle">
            {np ? "कुल भुक्तानी योग्य रकम" : "Maturity Amount"}
          </h4>
          <h4 className="emiCalculatorResultMonthlyEMI">
            {commaM ? commaM : 0}
          </h4>
        </div>

        <div className="emiCalculatorResultRowContainer">
          <p className="emiCalculatorResultRowText">
            {np ? "प्रिन्सिपल" : "Principal"}
          </p>
          <p className="emiCalculatorResultRowAns">
            {np ? "रु." : "Rs."} {commapAmount ? commapAmount : 0}
          </p>
        </div>

        <div className="emiCalculatorResultRowContainer">
          <p className="emiCalculatorResultRowText">
            {np ? "मिश्रित समय" : "Installment Amount"}
          </p>
          <p className="emiCalculatorResultRowAns">
            {np ? "रु." : "Rs."}{" "}
            {commaTotalInstallment ? commaTotalInstallment : 0}
          </p>
        </div>

        <div className="emiCalculatorResultRowContainer">
          <p className="emiCalculatorResultRowText">
            {np ? "ब्याज भुक्तान योग्य" : "Interest Receivable"}
          </p>
          <p className="emiCalculatorResultRowAns">
            {np ? "रु." : "Rs."}{" "}
            {commaInterestReceivable ? commaInterestReceivable : 0}
          </p>
        </div>
      </div>

      <div className="emiCalculatorChartContainer">
        <Doughnut data={chartData} />
      </div>
    </div>
  );
};

export default RecurringCalculator;
