import React, { useState, useRef } from "react";
import Controls from "./Controls";
import DisplayTrack from "./DisplayTrack";
import ProgressBar from "./ProgressBar";
import "./audioNoticeDrop.scss";

const AudioNoticeDrop = ({ audio }) => {
  const [currentTrack, setCurrentTrack] = useState(audio[0]);

  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const [trackIndex, setTrackIndex] = useState(0);

  // reference
  const audioRef = useRef();
  const progressBarRef = useRef();
  console.log("audioRef =>", audioRef);

  const handleNext = () => {
    if (trackIndex >= audio.length - 1) {
      setTrackIndex(0);
      setCurrentTrack(audio[0]);
    } else {
      setTrackIndex((prev) => prev + 1);
      setCurrentTrack(audio[trackIndex + 1]);
    }
  };

  return (
    <div className="audio-player">
      <div className="inner">
        <DisplayTrack
          {...{
            currentTrack,
            audioRef,
            setDuration,
            progressBarRef,
            handleNext,
          }}
        />
        <ProgressBar
          {...{ progressBarRef, audioRef, timeProgress, duration }}
        />

        <Controls
          {...{
            audioRef,
            progressBarRef,
            duration,
            setTimeProgress,
            audio,
            trackIndex,
            setTrackIndex,
            setCurrentTrack,
            handleNext,
          }}
        />
      </div>
    </div>
  );
};

export default AudioNoticeDrop;
