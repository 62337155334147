import React from "react";
import { Grid, ListItem } from "@mui/material";
import { Outlet, useParams, useLocation } from "react-router-dom";
// import Link from '@mui/material/Link'
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import main3 from "../assets/banner/banner02.webp";

import { Link } from "react-router-dom";

// function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
//     event.preventDefault();
//     console.info('You clicked a breadcrumb.');
// }

const Bread = ({ data, children }) => {
  // const { articleId } = useParams();
  // let location = useLocation();
  // console.log('params =>', location);
  // console.log(articleId);
  // console.log(data.title);
  const breadcrumbackground = {
    backgroundImage: `url(${main3})`,
  };
  const breadcrumbs = [
    <Link to="/" key="1">
      {data.l1}
    </Link>,
    data.l2 ? (
      <Link to={data.link} key="2">
        {data.l2}
      </Link>
    ) : (
      ""
    ),
    data.l3 ? (
      <Typography key="3" color="text.primary">
        {data.l3}
      </Typography>
    ) : (
      ""
    ),
  ];
  return (
    <>
      <div className="page-title">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
        <h1>{data.title}</h1>
      </div>

      <section className="main_cont">{children}</section>
    </>
  );
};

export default Bread;
