import React from 'react'
import { useQuery } from "@tanstack/react-query";
import Bread from '../../common/Bread'
import Loader from '../../common/Loader'
import axios from "axios";
import NepaliDate from 'nepali-date'
import {
  ButtonBack, ButtonNext,
  CarouselProvider, Slide, Slider, Image
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import './calendar.scss'

const Calendar = () => {

const d1 = new NepaliDate().month;

// console.log("d1 =>", typeof d1, d1);

const breadcrumList = {
  image: '',
  title: 'Calendar',
  l1: 'Home',
  l2: 'Calendar',
  l3: ''  
}

    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ["calendarData"],
        queryFn: () =>
          axios
            .get(`${process.env.REACT_APP_BASE_URL}/calendar`)
            .then((res) => res.data),
      });
  
      if (isFetching) return(
        <Loader />
      );
    
      if (isLoading) return(
        <Loader />
      );
    
      if (error) return "An error has occurred: " + error.message;

  return (
    <Bread data={breadcrumList} >
      <div className='calendarPageMainContainer'>
        <CarouselProvider
          className='calendar-carousel-provider'
          visibleSlides={1}
          totalSlides={data.calendars.length}
          step={1}
          naturalSlideWidth={1}
          naturalSlideHeight={1}
          hasMasterSpinner
          isPlaying={false}
          interval={10000}
          infinite={true}
          currentSlide={d1?d1:0}
        >
          <div className='btn-ctrl'>
          <ButtonBack 
          className='cus-back'>
            <BsChevronLeft
              className='btn-left'
            />
          </ButtonBack>

          <ButtonNext 
          className='cus-next'>
            <BsChevronRight
            className='btn-right'
            />
          </ButtonNext>
          </div>
          
          <Slider className='sliderContainer'>
            {data.calendars.map((i, index) => 
              <Slide key={index} className="singleSlide" index={index}>
                <Image className='calendarImage' src={i.DocPath} alt={i.Title} />
              </Slide>
            )}
          </Slider>
        </CarouselProvider>
      </div>
    </Bread>
  )
}

export default Calendar