import { parseISO, formatDistanceToNow, formatDistance } from "date-fns";

import React from 'react'

const TimeAgo = ({ timestamp }) => {
    let timeago = ''
    if (timestamp) {
        const date = parseISO(timestamp)
        const timeperiod = formatDistanceToNow(date)
        // const timeperiod = formatDistance(date, Date.now(), { addSuffix: true })
        timeago = `${timeperiod} ago`
    }
    return (
        <span className='date_news'>{timeago}</span>
    )
}

export default TimeAgo