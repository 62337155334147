import React, { useState, useRef, useMemo } from "react";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";

import { BiVolumeMute, BiVolumeFull } from "react-icons/bi";
import { BsPlayFill, BsFillPauseFill } from "react-icons/bs";
import banner from "../../assets/banner/banner02.webp";

import "./banner.scss";

const Banner = ({ data }) => {
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isHidden, setIsHidden] = useState(false);
  const videoRef = useRef(null);

  function playTimeout() {
    if (isPlaying === true) {
      const timer = setTimeout(() => setIsHidden(true), 1500);
      return () => clearTimeout(timer);
    } else {
      setIsHidden(false);
    }
    return;
  }

  var hideMemo = useMemo(playTimeout, [isPlaying]);

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <CarouselProvider
      visibleSlides={1}
      totalSlides={data?.length}
      step={1}
      naturalSlideWidth={16}
      naturalSlideHeight={9}
      hasMasterSpinner={false}
      isPlaying={true}
      interval={5000}
      infinite={true}
      className="banner-carousel"
    >
      <Slider>
        {data.map((i, index) => (
          <Slide key={index} index={index}>
            {i.file_type === "video" ? (
              <div className="landingBannerVidContainer">
                <video
                  ref={videoRef}
                  autoPlay={true}
                  muted={isMuted}
                  loop={true}
                  playsinline
                  poster={banner}
                >
                  <source src={i.file} type="video/webm"></source>
                </video>
                <div onClick={() => togglePlay()} className="vid-overlay">
                  {isPlaying ? (
                    <>
                      {!isHidden && (
                        <BsFillPauseFill
                          onClick={() => togglePlay()}
                          className="video-play-pause-btn"
                          color="#fff"
                        />
                      )}
                    </>
                  ) : (
                    <BsPlayFill
                      onClick={() => togglePlay()}
                      className="video-play-pause-btn"
                      color="#fff"
                    />
                  )}
                </div>

                {!isMuted ? (
                  <BiVolumeFull
                    onClick={() => toggleMute()}
                    className="vid-controls"
                    size="48px"
                    color="#fff"
                  />
                ) : (
                  <BiVolumeMute
                    onClick={() => toggleMute()}
                    className="vid-controls"
                    size="48px"
                    color="#fff"
                  />
                )}
              </div>
            ) : (
              <div className="landingBannerContainer" index={1}>
                <img className="landingBanner" src={i.file} alt={index} />
              </div>
            )}
          </Slide>
        ))}
      </Slider>
    </CarouselProvider>
  );
};

export default Banner;
