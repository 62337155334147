import React from 'react'
import { LangContext } from "../../context/LangContext";
import savAc from "../../assets/service/saving-account.png";
import homeLoan from "../../assets/service/home-loan.png";
import intRate from "../../assets/service/int-rate.png";
// import { MdOutlineArrowForwardIos } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import listTriangle from "../../assets/extras/listTraingle2.svg";
import Bread from '../../common/Bread'
import './product.scss'

const Product = () => {
    const navigate = useNavigate();

    const lang = React.useContext(LangContext);
    const np = lang.state.np;

    const breadcrumList = {
        image: '',
        title: 'Products',
        l1: 'Home',
        l2: 'Product Categories',
        l3: ''
    }

  return (
    <Bread data={breadcrumList}>
    <div className='product-main-container'>

<div className="serviceItemContainer">
                <img src={savAc} alt="saving-account" />
                <h2>{np ? "बचत खाताहरू" : "Savings Account"}</h2>

                <ul
                  className="serviceItemList"
                  style={{ listStyleImage: `url(${listTriangle})` }}
                >
                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                    {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                    {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                  </li>

                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill className='serviceListIcon' />                 */}
                    {np ? "कसरी" : "Frequently Asked Questions"}
                  </li>

                  <li className="serviceItemListItem">
                    {np ? "योग्यता र कागजात" : "Eligibility & Documentation"}
                  </li>
                </ul>

                {/* add double classname for two buttons */}
                <div className="ButtonContainer">
                  {/* <div 
                    whileHover={{
                        scale: 1.05
                    }}
                    whileTap={{ scale: 0.8 }}
                    className='filled-btn'>
                        {np?'आवेदन दिनुहोस्':'Apply Now'}
                    </div> */}

                  <div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className="know-more-button"
                    onClick={() =>
                      navigate(
                        "/product/saving-account/icfc-bumper-saving-account"
                      )
                    }
                  >
                    {np ? "थप जान्नुहोस्" : "Know More"}
                  </div>
                </div>
              </div>

              {/* two */}
              <div className="serviceItemContainer">
                <img src={homeLoan} alt="home-loan" />
                <h2>{np ? "कर्जा" : "Loan Products"}</h2>

                <ul
                  className="serviceItemList"
                  style={{ listStyleImage: `url(${listTriangle})` }}
                >
                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                    {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                    {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                  </li>

                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill className='serviceListIcon' />                 */}
                    {np ? "कसरी" : "Frequently Asked Questions"}
                  </li>

                  <li className="serviceItemListItem">
                    {np ? "योग्यता र कागजात" : "Eligibility & Documentation"}
                  </li>
                </ul>

                <div className="ButtonContainer">
                  {/* <div 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className='filled-btn'>
                        {np?'आवेदन दिनुहोस्':'Apply Now'}
                    </div> */}

                  <div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className="know-more-button"
                    onClick={() =>
                      navigate("/product/professional-loan/sme-loan")
                    }
                  >
                    {np ? "थप जान्नुहोस्" : "Know More"}
                  </div>
                </div>
              </div>
              {/* end two */}

              {/* three */}
              <div className="serviceItemContainer">
                <img src={intRate} alt="intRate" />
                <h2>{np ? "फिक्स्ड डिपोजिट" : "Fixed Deposit"}</h2>

                <ul
                  className="serviceItemList"
                  style={{ listStyleImage: `url(${listTriangle})` }}
                >
                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                    {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                    {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                  </li>

                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill className='serviceListIcon' />                 */}
                    {np ? "कसरी" : "Frequently Asked Questions"}
                  </li>

                  <li className="serviceItemListItem">
                    {np ? "योग्यता र कागजात" : "Eligibility & Documentation"}
                  </li>
                </ul>

                <div className="ButtonContainer">
                  {/* <div 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className='filled-btn'>
                        Apply Now
                    </div> */}

                  <div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className="know-more-button"
                    onClick={() => navigate("/product/fixed-deposit/recurring-fixed-deposit")}
                  >
                    {np ? "थप जान्नुहोस्" : "Know More"}
                  </div>
                </div>
              </div>
              {/* end three */}

    </div>
    </Bread>
  )
}

export default Product