import {useMemo} from 'react';
import { useLocation } from "react-router-dom";

const LastVisited = () => {
    const { pathname } = useLocation();
    
    function getPath () {

        const date = () => {

            const options = {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };

            const d = new Date(); 

            return d.toLocaleDateString('en-us', options);
        }
        
        return (
            {
                pathname: pathname,
                time: date(),
            }
        );
    }

    var pathMemo = useMemo(getPath, [pathname]);

    window.onbeforeunload = function() {
        localStorage.setItem('lastVisit', JSON.stringify(pathMemo));
        localStorage.removeItem('visit_status');
        return;
    }

 return;
}

export default LastVisited;