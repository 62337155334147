import { useState, useEffect, useCallback, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import FastRewindRounded from "@mui/icons-material/FastRewindRounded";
import SkipNext from "@mui/icons-material/SkipNext";
import SkipPrevious from "@mui/icons-material/SkipPrevious";
import VolumeUpRounded from "@mui/icons-material/VolumeUpRounded";
import VolumeDownRounded from "@mui/icons-material/VolumeDownRounded";

const Controls = ({
  audioRef,
  progressBarRef,
  duration,
  setTimeProgress,
  audio,
  trackIndex,
  setTrackIndex,
  setCurrentTrack,
  handleNext,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [muteVolume, setMuteVolume] = useState(false);
  const [volume, setVolume] = useState(60);

  const playAnimationRef = useRef();

  const repeat = useCallback(() => {
    const currentTime = audioRef.current.currentTime;
    setTimeProgress(currentTime);
    progressBarRef.current.value = currentTime;
    progressBarRef.current.style.setProperty(
      "--range-progress",
      `${(progressBarRef.current.value / duration) * 100}%`
    );

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, duration, progressBarRef, setTimeProgress]);

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying, audioRef, repeat]);

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
      audioRef.current.muted = muteVolume;
    }
  }, [volume, audioRef, muteVolume]);

  const skipForward = () => {
    audioRef.current.currentTime += 15;
  };

  const skipBackward = () => {
    audioRef.current.currentTime -= 15;
  };

  const handlePrevious = () => {
    if (trackIndex === 0) {
      let lastTrackIndex = audio.length - 1;
      setTrackIndex(lastTrackIndex);
      setCurrentTrack(audio[lastTrackIndex]);
    } else {
      setTrackIndex((prev) => prev - 1);
      setCurrentTrack(audio[trackIndex - 1]);
    }
  };

  return (
    <div className="controls-wrapper">
      <div className="controls">
        <IconButton onClick={handlePrevious} aria-label="skip-previous">
          <SkipPrevious fontSize="large" htmlColor="#282C35" />
        </IconButton>

        <IconButton onClick={skipBackward} aria-label="skip-backward">
          <FastRewindRounded fontSize="large" htmlColor="#282C35" />
        </IconButton>

        <IconButton
          aria-label={isPlaying ? "pause" : "play"}
          onClick={togglePlayPause}
        >
          {isPlaying ? (
            <PauseRounded sx={{ fontSize: "3rem" }} htmlColor="#282C35" />
          ) : (
            <PlayArrowRounded sx={{ fontSize: "3rem" }} htmlColor="#282C35" />
          )}
        </IconButton>
        <IconButton onClick={skipForward} aria-label="skip-forward">
          <FastForwardRounded fontSize="large" htmlColor="#282C35" />
        </IconButton>
        <IconButton onClick={handleNext} aria-label="skip-next">
          <SkipNext fontSize="large" htmlColor="#282C35" />
        </IconButton>
      </div>

      <div className="volume">
        {/* <button onClick={() => setMuteVolume((prev) => !prev)}>
    {muteVolume || volume < 5 ? (
        <IoMdVolumeOff />
    ) : volume < 40 ? (
        <IoMdVolumeLow />
    ) : (
        <IoMdVolumeHigh />
    )}
    </button> */}
        <VolumeDownRounded htmlColor="#00000080" sx={{ mr: "12px" }} />
        <input
          type="range"
          min={0}
          max={100}
          value={volume}
          onChange={(e) => setVolume(e.target.value)}
        />
        <VolumeUpRounded htmlColor="#00000080" sx={{ ml: "12px" }} />
      </div>
    </div>
  );
};

export default Controls;
