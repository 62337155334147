import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Bread from "../../common/Bread";
import "./branch.scss";
import BranchMap from "../../components/BranchMap";
import Loader from "../../common/Loader";
import BranchList from "../../components/BranchList";

const Branch = () => {
  const [selected, setSelected] = React.useState({
    id: null,
    lat: null,
    lng: null,
  });

  const handleReset = () => {
    setSelected({
      id: null,
      lat: null,
      lng: null,
    });
  };

  const callbackSelect = ({ id, lat, lng }) => {
    setSelected({ id: id, lat: lat, lng: lng });
  };

  const breadcrumList = {
    image: "",
    title: "Branch Locations",
    l1: "Home",
    l2: "Branches",
    l3: "",
  };

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["branchData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/branch`)
        .then((res) => res.data),
  });

  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;

  return (
    <Bread data={breadcrumList}>
      <Tabs className="branchesPageTabContainer" defaultIndex={0}>
        <TabList className="branchesPageTabsButtonContainer">
          <Tab className="branchesPageTabButton" onClick={handleReset}>
            Inside Kathmandu Valley
          </Tab>

          <Tab className="branchesPageTabButton" onClick={handleReset}>
            Outside Valley
          </Tab>
        </TabList>

        <TabPanel>
          <div className="branchesPageContainer">
            <BranchList
              data={data?.branch.en.filter(
                (i) => i.Valley.toLowerCase() === "inside"
              )}
              callbackSelect={callbackSelect}
            />

            <div className="branchesMapContainer">
              <BranchMap
                data={data?.branch.en.filter(
                  (i) => i.Valley.toLowerCase() === "inside"
                )}
                selected={selected}
              />
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="branchesPageContainer">
            <BranchList
              data={data?.branch.en.filter(
                (i) => i.Valley.toLowerCase() === "outside"
              )}
              callbackSelect={callbackSelect}
            />

            <div className="branchesMapContainer">
              <BranchMap
                data={data?.branch.en.filter(
                  (i) => i.Valley.toLowerCase() === "outside"
                )}
                selected={selected}
              />
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </Bread>
  );
};

export default Branch;
