import React from 'react'
import axios from 'axios';
import Bread from '../../common/Bread'
import { useQuery } from "@tanstack/react-query";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './memberNetwork.scss'
import Loader from '../../common/Loader';

const MemberNetwork = () => {
  const breadcrumList = {
    image: '',
    title: 'Member Network',
    l1: 'Home',
    l2: 'Member Network',
    l3: ''  
  }

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["memberData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/member_network`)
        .then((res) => res.data),
  });

  if (isFetching) return(
    <Loader />
  );

  if (isLoading) return(
    <Loader />
  );

  if (error) return "An error has occurred: " + error.message;

  return (
    <Bread data={breadcrumList}>
        <div className='memberPageMainContainer'>
          <Tabs className='tabs' defaultIndex={1}>
            <TabList className='tabList'>
              {data.member_network_category.map((value, index) => 
                <Tab key={index} className='button'>{value.title}</Tab>
              )}
              
            </TabList>

            {data.member_network_category.map((item, index) => 
              <TabPanel key={index}>
              <div className='resultsContainer'>
                {item.networks.length > 0 ? (
                  item.networks.map((i, index) => 
                  <div key={index} className='item'>
                    <p>{i.title}</p>
                  </div>                
                  )
                ) : (
                  <h2>No data</h2>
                )}

              </div>
            </TabPanel>            
            )}


            {/* <TabPanel key={1}>
              <h1>two</h1>
            </TabPanel> */}
          </Tabs>
        </div>
    </Bread>
  )
}

export default MemberNetwork