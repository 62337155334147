import React, { useState, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Bread from "../../common/Bread";
import { LangContext } from "../../context/LangContext";
import "./investor.scss";
import Loader from "../../common/Loader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#003399",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "DM Sans, sans-serif",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(173, 216, 230, 0.4)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    //   border: 0,
  },
}));

const InvestorCK = () => {
  const items = useRef([]);
  const [title, setTitle] = useState("");
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;

  const { slug } = useParams();

  function titleSwitch() {
    switch (slug) {
      case "capital_structure":
        {
          setTitle("Capital Structure");
        }
        break;
      case "base_rates":
        {
          setTitle("Base Rate");
        }
        break;
      case "spread_rates":
        {
          setTitle("Interest Spread Rate");
        }
        break;
      case "cost_of_fund":
        {
          setTitle("Cost of Fund");
        }
        break;
      default:
        {
          setTitle("Investor Relation");
        }
        break;
    }
  }

  var breadMemo = useMemo(titleSwitch, [slug]);

  const breadcrumList = {
    image: "",
    title: title,
    l1: "Home",
    l2: "Investor Relation",
    l3: "",
  };

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["investorCKData", slug],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/invest_interest`)
        .then((res) => res.data),
  });

  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;

  return (
    <Bread data={breadcrumList}>
      <div className="investorContainer">
        {slug == "capital_structure" ? (
          <div
            className="services-ck-content"
            dangerouslySetInnerHTML={{
              __html: data.capital_structure.en.Description,
            }}
          ></div>
        ) : slug == "base_rates" ? (
          <div className="InterestPageTableContainer">
            <TableContainer
              sx={{ border: "1px solid #c2c2c2" }}
              component={Paper}
            >
              <Table sx={{ minWidth: 600 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ fontSize: "17px" }} align="left">
                      Month
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontSize: "17px" }} align="left">
                      Base Rate
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.base_rates.en.map((item, index) => (
                    <StyledTableRow
                      key={index}
                      className="InterestPageTableRow"
                    >
                      <StyledTableCell
                        sx={{ width: "70%" }}
                        className="InterestPageTableCell InterestPageTableTitleText"
                        align="left"
                      >
                        {item.Title}
                      </StyledTableCell>

                      <StyledTableCell
                        className="InterestPageTableCell InterestPageTableTitleText"
                        sx={{ width: "30%" }}
                        align="left"
                      >
                        {Number(item.rate).toFixed(2)}%
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : slug == "spread_rates" ? (
          <div className="InterestPageTableContainer">
            <TableContainer
              sx={{ border: "1px solid #c2c2c2" }}
              component={Paper}
            >
              <Table sx={{ minWidth: 600 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ fontSize: "17px" }} align="left">
                      Month
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontSize: "17px" }} align="left">
                      Interest Spread Rate
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.spread_rates.en.map((item, index) => (
                    <StyledTableRow
                      key={index}
                      className="InterestPageTableRow"
                    >
                      <StyledTableCell
                        sx={{ width: "70%" }}
                        className="InterestPageTableCell InterestPageTableTitleText"
                        align="left"
                      >
                        {item.Title}
                      </StyledTableCell>

                      <StyledTableCell
                        className="InterestPageTableCell InterestPageTableTitleText"
                        sx={{ width: "30%" }}
                        align="left"
                      >
                        {Number(item.rate).toFixed(2)}%
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : slug == "cost_of_fund" ? (
          <div className="InterestPageTableContainer">
            <TableContainer
              sx={{ border: "1px solid #c2c2c2" }}
              component={Paper}
            >
              <Table sx={{ minWidth: 600 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ fontSize: "17px" }} align="left">
                      Month
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontSize: "17px" }} align="left">
                      Cost of Fund
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.cost_of_fund?.en?.map((item, index) => (
                    <StyledTableRow
                      key={index}
                      className="InterestPageTableRow"
                    >
                      <StyledTableCell
                        sx={{ width: "70%" }}
                        className="InterestPageTableCell InterestPageTableTitleText"
                        align="left"
                      >
                        {item.Title}
                      </StyledTableCell>

                      <StyledTableCell
                        className="InterestPageTableCell InterestPageTableTitleText"
                        sx={{ width: "30%" }}
                        align="left"
                      >
                        {Number(item.rate).toFixed(2)}%
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <div className="ck-content">
            <h1>no data</h1>
          </div>
        )}
      </div>
    </Bread>
  );
};

export default InvestorCK;
