import React, { useRef } from 'react'
import Bread from '../../common/Bread'
import TimeAgo from '../../common/TimeAgo'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { LangContext } from '../../context/LangContext'
import { useQuery } from "@tanstack/react-query";
import AxiosClass from '../../common/AxiosClass';

import Loader from '../../common/Loader'

const Detail = () => {

    const item = useRef({});

    const lang = React.useContext(LangContext);
    const nep = lang.state.np;

    const { id } = useParams()

    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ["careerDataSingle"],
        queryFn: () =>
            AxiosClass.getApi(`/career/details/${id}`)
                .then((res) => res.data),
    });

    if (isFetching) return(
        <Loader />
      );
    
      if (isLoading) return(
        <Loader />
      );

    if (error) return "An error has occurred: " + error.message;

    // console.log(data);

    let breadcrumList;

    if (nep) {
        // item.current = data.detail.np;

        breadcrumList = {
            image: '',
            link: '/career',
            title: `${data?.detail?.TitleNepali}`,
            l1: 'होम',
            l2: 'क्यारियर',
            l3: `${item?.detail?.TitleNepali}`,
        }
    } else {
        // item.current = data.detail.en;

        breadcrumList = {
            image: '',
            link: '/career',
            title: `${data?.detail?.Title}`,
            l1: 'Home',
            l2: 'career',
            l3: `${data?.detail?.Title}`,
        }
    }

    // const breadcrumList = {
    //     image: '',
    //     link: '/news',
    //     title: 'News For Test',
    //     l1: 'Home',
    //     l2: 'News',
    //     l3: slug
    // }

    return (
        <Bread data={breadcrumList}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12} sm={12} md={12} key={1}>
                    <div className='detail_content'>
                        <div className='careerImgCont'>
                            <img className='careerImg' src={data?.detail?.DocPath} alt={data?.detail?.slug} />
                        </div>
                        <div className='detail_content'>
                            {/* <h1>Title Goes Here</h1> */}
                            {/* <TimeAgo timestamp={data?.detail?.created_on} /> */}
                            {/* {item.current.DocPath ? <p><a href={item.current.DocPath} target="_blank">View File</a></p> : ''} */}
                            {nep?(
                            <p dangerouslySetInnerHTML={{ __html: data?.detail?.DescriptionNepali }}></p>
                            ):(
                              <p dangerouslySetInnerHTML={{ __html: data?.detail?.Description }}></p>
                            )}

                        </div>
                    </div>
                </Grid>
            </Grid>
        </Bread>
    )
}

export default Detail