import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { LangContext } from "../../context/LangContext";
import { VscFilePdf } from "react-icons/vsc";
import Bread from "../../common/Bread";
import "./learning.scss";
import Loader from "../../common/Loader";

const LearningDevelopment = () => {
  const lang = React.useContext(LangContext);
  const np = lang.state.np;
  const MianData = React.useState([]);
  const MianDataCat = React.useState([]);
  const breadcrumList = {
    image: "",
    link: "",
    title: "Learning and Development",
    l1: "Home",
    l2: "Learning and Development",
    l3: "",
  };

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["LearningData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/Learning_dev/all`)
        .then((res) => res.data),
  });
  if (np) {
    MianDataCat.current = data?.items?.np;
  } else {
    MianDataCat.current = data?.items?.en;
  }
  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;
  console.log(MianDataCat.current);
  return (
    <Bread data={breadcrumList}>
      <div className="LerningBox">
        {MianDataCat?.current.length == 0
          ? "No Data"
          : MianDataCat?.current?.map((item, index) => (
              <div className="lerningItemsbox" key={index}>
                <a
                  key={index}
                  href={item.DocPath}
                  target="_blank"
                  className="leriningitems"
                >
                  <p className="downloadsPageResultText">{item.Title}</p>
                  <VscFilePdf className="icon" color="#3a3a3a" size="25px" />
                </a>
              </div>
            ))}
      </div>
    </Bread>
  );
};

export default LearningDevelopment;
