import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { VscFilePdf } from "react-icons/vsc";
import Bread from "../../common/Bread";
import Loader from "../../common/Loader";

const YearInterestRates = () => {
  const breadcrumList = {
    image: "",
    link: "",
    title: "Interest Rates (Fiscal Year)",
    l1: "Home",
    l2: "Interest Rates",
    l3: "",
  };

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["fiscalInterestData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/fiscal_year_wise_interest`)
        .then((res) => res.data),
  });

  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;

  return (
    <Bread data={breadcrumList}>
      <div className="downloadsPageMainContainer">
        <Tabs className="downloadsPageTabContainer" defaultIndex={0}>
          <TabList className="downloadsPageTabsButtonContainer">
            {data.items.map((value, index) => (
              <Tab key={index} className="downloadsPageTabButton">
                {value.title}
              </Tab>
            ))}
          </TabList>

          {data.items.map((item, index) => (
            <TabPanel key={index}>
              <div className="downloadsPageResultContainer">
                {item.interest_rates.length > 0 ? (
                  item.interest_rates.map((download, index) => (
                    <a
                      key={index}
                      href={download.DocPath}
                      className="downloadsPageResultItem"
                    >
                      <p className="downloadsPageResultText">
                        {download.Title}
                      </p>
                      <VscFilePdf
                        className="icon"
                        color="#3a3a3a"
                        size="25px"
                      />
                    </a>
                  ))
                ) : (
                  <h2>No data</h2>
                )}
              </div>
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </Bread>
  );
};

export default YearInterestRates;
