import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Bread from '../../common/Bread'
import Loader from '../../common/Loader';
import { Pagination } from '@mui/material';


const MonthlyInterestRates = () => {
    const [isPage, setIsPage] = useState(1);

    const breadcrumList = {
        image: '',
        title: 'Interest Rates',
        l1: 'Home',
        l2: 'Interest Rates',
        l3: ''
    }

    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ["monthInterestData"],
        queryFn: () =>
          axios
            .get(`${process.env.REACT_APP_BASE_URL}/interest_rates/all/${isPage}`)
            .then((res) => res.data),
      });
  
      if (isFetching)
        return(
          <Loader />
        ); 
  
      if (isLoading) return(
        <Loader />
      );
    
      if (error) return "An error has occurred: " + error.message;

    return (
        <Bread data={breadcrumList} >
            <div className="services-ck-content" dangerouslySetInnerHTML={{ __html: data.items[0].Description }}>
            
            </div>    
        </Bread>
    )
}

export default MonthlyInterestRates
