import React,{useState, useRef, useCallback} from 'react'
import {
    GoogleMap,
    Marker,
    InfoWindow,
    useLoadScript
  } from "@react-google-maps/api";


const locData = [
    {
        id: 1,
        title: 'Gwarko',
        lat: '27.6666875',
        lng: '85.3318125',
    },
    {
        id: 2,
        title: 'Boudha',
        lat: '27.7218125',
        lng: '85.3741875',
    }
]

const ContactMap = () => {
    const [activeMarker, setActiveMarker] = useState(null);
    const [zoom, setZoom] = useState(8);
    const mapRef = useRef();

    const [center, setCenter] = useState({
      lat: 27.7172,
      lng: 85.3240,
    })

    const onLoad = useCallback((map) => (mapRef.current = map), []);

    const containerStyle = {
        // width: '400px',
        height: '70vh'
    };

    const handleActiveMarker = (markerd) => {
        if (markerd === activeMarker) {
          return;
        }
        setActiveMarker(markerd);
        setZoom(15);
    };

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDCi1XRM_e1FjEN7enq4xIuzLVdc8V7Mms"
    });

  return isLoaded? (
   <>
    <GoogleMap
        zoom={zoom}
        center={center}
        mapContainerStyle={containerStyle}
        // onLoad={onLoad}
    >
      {locData?.map((pos) => (
            <Marker
              key={pos.id}
              onLoad={onLoad}
              position={{ lat: Number(pos.lat), lng: Number(pos.lng) }}
              onClick={() => handleActiveMarker(pos.id)}
            >
            {activeMarker === pos.id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>{pos.title}</div>
              </InfoWindow>
            ) : null}
            </Marker>
          ))}
    </GoogleMap>
   </>
  ) : (
      <p>Loading...</p>
  )

}

export default ContactMap