import React,{useState, useEffect} from 'react'
import { LangContext } from '../../context/LangContext';
import { useForm } from 'react-hook-form';
import {AiOutlineSearch} from 'react-icons/ai'
import Pagination from '@mui/material/Pagination';
import {toast} from 'react-toastify'
import {ClipLoader} from 'react-spinners'
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import know from '../../assets/search/know3.png';
import searchIn from '../../assets/search/searchIn.svg';
import noData from '../../assets/search/noData.svg';
import Bread from '../../common/Bread'
import './search.scss'

const Search = () => {

  const navigate = useNavigate()

    const lang = React.useContext(LangContext);
    const np = lang.state.np;

    const { register, handleSubmit, formState: { errors }, formState } = useForm();
    const { isDirty, isSubmitting, isSubmitSuccessful } = formState;
    const [submitLoading, setSubmitLoading] = useState(false);
    console.log("submitLoading =>", typeof submitLoading, submitLoading);
    const [searchData, setSearchData] = useState(null)
    console.log("searchData =>", typeof searchData, searchData);
    const [searchWord, setSearchWord] = useState('anbasdgxavcv');
    console.log("searchWord =>", typeof searchWord, searchWord);
    const [dataPagination, setDataPagination] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
        //pageApi
    const [pageApi, setPageApi] = useState(1);

    const breadcrumList = {
        image: '',
        link: '',
        title: 'Search',
        l1: 'Home',
        l2: "Search",
        l3: ''
    }

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        try {
          const { data } = await axios.get('https://demo.nyatapol.com/dashicfc/api/search/pagination/' + searchWord + '/' + pageApi);
          setDataPagination(data);
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
        
      }
  
      fetchData()
    }, [pageApi, searchData])

    const onSubmit = async (values) => {
        setSubmitLoading(true);
        let valueLower = values.search_word.toLowerCase();
        let valueRemoveSpace = valueLower.trim().replace(/\s+/g, '-');
        setSearchWord(valueRemoveSpace);
    
        try {
          setLoading(true);
          const { data } = await axios.post('https://demo.nyatapol.com/dashicfc/api/search/search', values);
          
          if(data?.error){
            toast.error(data.error);
            setSubmitLoading(false);
            setLoading(false);
          }else {
            setSearchData(data);
            setSubmitLoading(false);
            setLoading(false);
          }
    
        } catch (err) {
          toast.error("Failed to send. Please try again.")
          console.log(err)
          setSubmitLoading(false);
          setLoading(false);
        }
      }
      

  return (
    <Bread data={breadcrumList} >
        <div className='searchPageMainContainer'>
        <form onSubmit={handleSubmit(onSubmit)}>
            <input
                className='dialogSearchInput' 
                type="text"
                placeholder={np? "खोज ..." : "Search…"}
                name="search_word"
                {...register("search_word", {
                required: "Please type what you would like to search for",
                pattern: { value: /^.{2,200}$/, message: "Message must be between 20 to 50 characters" },
                })}
            />
            <button
                style={{ borderRadius: 0 }} 
                className='contactFormResetButton contactSubmitButton'
                type='submit' disabled={isSubmitting} >
                <AiOutlineSearch
                  fontSize="20px" 
                />

                {window.screen.width > 500 && 'Search'} 
                
            </button>
        </form>

        {searchWord === 'anbasdgxavcv' ? (
            <div className='searchPageConditionContainer'>
              <img className='searchPageConditionImage' src={searchIn} alt="search Input" />
              <h1 className='searchPageConditionText'>Please type the keywords to continue the search</h1>
            </div>

          )
            :
            (
              <>
              {dataPagination?.items.length > 0 ? 
              (

                <>
            {loading &&  <div className="loaderContainer"><ClipLoader size={100} color='#dd1134' loading /></div>}

            {error && <div>Error: {error.message}</div>}
            {!loading && (
                <>
                <div className='searchDialogResultContainer'>
                {dataPagination?.items.map((item) => 
                    // <Link 
                    // to={`${item.route}${item.slug}`}>
                    <div onClick={() => navigate(`${item.route}${item.slug}`)} className='searchResultsItem' key={item.id}>
                    <span>{item.module}</span>

                    <div 
                    className='searchResultItemTitle'>{item.title}</div>
                    <div 
                    className='searchKnowContainer'>
                          <a className='searchResultKnow'>Know More</a>
                            <img
                                className='serviceKnowImg'
                                src={know}
                            />
                    </div>
                  </div>
                    // {/* </Link> */}

                )}
              </div>
                </>
            )}

              {dataPagination?.total >= dataPagination?.per_page && (
                <Pagination style={{ marginTop: '32px' }} className='paginationContainer' count={Math.ceil(dataPagination?.total / dataPagination?.per_page)} color="primary" size="large" onChange={(e, value) => setPageApi(value)} />     
              )}
    
 
              </>
              ) : (
                <>
                {loading &&  <div className="loaderContainer"><ClipLoader size={100} color='#dd1134' loading /></div>}

                {error && <div>Error: {error.message}</div>}
                {!loading && (
                <div className='searchPageConditionContainer'>
                  <img className='searchPageConditionImage' src={noData} alt="search Input" />
                  <h1 className='searchPageConditionText'>No data found. Please try searching with other keywords.</h1>
                </div>
                )}
                </>
              )
            }
              </>
            )
          }

        </div>
   
    </Bread>
  )
}

export default Search