import React, { useState, useEffect, useRef } from "react";
import Bread from "../../common/Bread";
import TimeAgo from "../../common/TimeAgo";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { LangContext } from "../../context/LangContext";
import { useQuery } from "@tanstack/react-query";
import AxiosClass from "../../common/AxiosClass";
import Loader from "../../common/Loader";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./details.css";
const Detail = () => {
  const item = useRef({});
  const { slug } = useParams();
  const items = useRef([]);
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const { isLoading, error, data, isFetching } = useQuery({
  //   queryKey: ["csrDataSingle"],
  //   queryFn: () =>
  //     AxiosClass.getApi(`/csr/detail/${slug}`).then((res) => res.data),
  // });

  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(
        `https://admin.icfcbank.com/api/csr/detail/${slug}`
      );
      setData(response.data);
      console.log("ALL", response.data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }
  if (loading) return "....";
  if (error) return "error";

  // if (isFetching) return <Loader />;

  // if (isLoading) return <Loader />;

  // if (error) return "An error has occurred: " + error.message;

  let breadcrumList;

  if (nep) {
    item.current = data?.detail.np;

    breadcrumList = {
      image: "",
      link: "/csr",
      title: `${item.current.Title}`,
      l1: "होम",
      l2: "कर्पोरेट सामाजिक उत्तरदायित्व",
      l3: `${item.current.Title}`,
    };
  } else {
    item.current = data?.detail.en;

    breadcrumList = {
      image: "",
      link: "/csr",
      title: `${item.current.Title}`,
      l1: "Home",
      l2: "CSR",
      l3: `${item.current.Title}`,
    };
  }

  console.log("item.current", item.current);
  return (
    <Bread data={breadcrumList}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={12} sm={12} md={12} key={1}>
          <div className="detail_content">
            <div className="image_detail">
              {item.current.DocPath && (
                <img src={item.current.DocPath} alt="gg" />
              )}
            </div>
            <div className="detail_content-child">
              {/* <TimeAgo timestamp={item.current.created_on} /> */}
              <div
                dangerouslySetInnerHTML={{ __html: item.current.Description }}
                className="services-ck-content marginTop"
              ></div>
              <div className="imageBox">
                {item?.current?.images?.map((image, index) => {
                  // console.log("image", image);
                  return (
                    <>
                      {/* <div className="img" onClick={handleOpen}>
                        <img src={image} alt={image} />
                      </div> */}

                      <a href={image} target="_blank">
                        <img src={image} alt={image} />
                      </a>

                      {/* <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box className="ModelImageBox">
                          <img src={image} alt={image} />
                        </Box>
                      </Modal> */}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Bread>
  );
};

export default Detail;
