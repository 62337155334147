import React from 'react';
import { LangContext } from '../../context/LangContext';

const DisplayTrack = ({
    currentTrack, 
    audioRef, 
    setDuration,
    progressBarRef,
    handleNext, 
}) => {
    const onLoadedMetadata = () => {
        const seconds = audioRef.current.duration;
        setDuration(seconds);
        progressBarRef.current.max = seconds;
      };

      const lang = React.useContext(LangContext);
      const np = lang.state.np;

  return (
    <div>
      <audio 
        src={currentTrack.DocPath}
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata} 
        onEnded={handleNext}
      />
      <div className="audio-info">
        {/* <div className="audio-image">
          {currentTrack.thumbnail ? (
            <img src={currentTrack.thumbnail} alt="audio avatar" />
          ) : (
            <div className="icon-wrapper">
              <span className="audio-icon">
                <BsMusicNoteBeamed />
              </span>
            </div>
          )}
        </div> */}
        <div className="text">
          <h3 className="title">{np ? (currentTrack.TitleNepali) : (currentTrack.Title)} </h3>
          <p>{np?(currentTrack.DescriptionNepali.substring(0,150)):(currentTrack.Description.substring(0,150))}  {}...</p>
        </div>
      </div>
    </div>
    
  )
}

export default DisplayTrack