import React from 'react'
import {
  ButtonBack, ButtonNext, CarouselProvider, Slide, Slider
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Link } from 'react-router-dom';
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { LangContext } from '../../context/LangContext';
import './forex.scss'

const Forex = ({ data }) => {
  const lang = React.useContext(LangContext);
  const np = lang.state.np;
  return (
    <div className='forexComponentContainer'>
        <div className='BlogsContentBox'>
          <Link to="/forex" className='view-all'>{np?'सबै हेर्नुहोस्':'View all'}</Link>
            <div className='slogun'>
                <h2>{np?'विदेशी मुद्रा':'Forex'}</h2>
                <p>As of {data[0].date_forex}.</p>
                <Link to="/forex" >{np?'सबै हेर्नुहोस्':'View all'}</Link>
            </div>
            {/* format(new Date(2014, 1, 11), 'yyyy-MM-dd') */}
            <div className='BBoxContent'>
            <CarouselProvider
                className='forex-carousel-provider'
                visibleSlides={window.innerWidth <= 480 ? 1 :  window.innerWidth <= 768 ? 3 : window.innerWidth <= 1024 ? 4 : window.innerWidth <= 1500 ? 5 : window.innerWidth <=1920? 6 : 7 }
                totalSlides={data[0].forex_values.length}
                step={1}
                naturalSlideWidth={16}
                naturalSlideHeight={9}
                hasMasterSpinner={false}
                isPlaying={true}
                interval={3000}
                infinite={true}
            >

              <div className='sliderContainer'>
              <Slider>
                  {data[0].forex_values.map((i, index) => (
                    <Slide key={i.id} index={index}>
                      <div className='homeForexSlide'>
                        <div className='flagRow'>
                          <div className={`currency-flag currency-flag-${i.iso3}`}></div>
                          <p>{i.iso3}</p>
                        </div>
                        <div className='priceRow'>
                          <div className='priceCol'>
                            <h4>Unit</h4>
                            <p>{i.unit}</p>
                          </div>

                          <div className='priceCol'>
                            <h4>Buy</h4>
                            <p>{i.buy}</p>
                          </div>
                          <div className='priceCol'>
                            <h4>Sell</h4>
                            <p>{i.sell}</p>
                          </div>

                        </div>
                      </div>
                    </Slide>
                  ))}
                </Slider>

              <ButtonBack 
                className='cus-back'>
                  <BsFillArrowLeftCircleFill
                    className='btn-left'
                  />
                </ButtonBack>

              <ButtonNext 
              className='cus-next'>
                <BsFillArrowRightCircleFill
                className='btn-right'
                />
              </ButtonNext>
              </div>

                {/* <DotGroup /> */}
            </CarouselProvider>

            </div>
        </div>
    </div>
  )
}

export default Forex