import Bread from "../../common/Bread";
import { BsTelephoneForwardFill, BsFillPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
// import griev from '../../assets/information/grievOfficerC.png'

const ItTeams = ({ data }) => {
  const breadcrumList = {
    image: "",
    title: "IT Officials",
    l1: "Home",
    l2: "Teams",
    l3: "IT Officials",
  };

  return (
    <Bread data={breadcrumList}>
      <div className="officers-grid-container">
        {data.items.en.map((i, index) => (
          <div key={index} className="officer">
            <div className="branch">
              <h3>{i.sub_designation}</h3>
            </div>

            <div className="paper">
              <div className="detail-container">
                <img src={i.featured_image} alt={i.email} />
                <p className="officer-name">{i.name}</p>
                <div>
                  <MdEmail />
                  <p>{i.email}</p>
                </div>

                <div>
                  <BsTelephoneForwardFill />
                  <p>01-4425292</p>
                </div>

                <div>
                  <BsFillPhoneFill />
                  <p>{i.contact}</p>
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* <div className='officer'>
                <div className='branch'>
                    <h3>Head Office Naxal</h3>
                </div>
                
                <div className='paper'>
                <div className='detail-container'>
                    <img src={griev} alt="grievance-officer" />
                    <p className='officer-name'>Mr. Amit Shrestha</p>
                    <div>
                    <MdEmail />
                    <p>amit.shrestha@icfcbank.com</p>
                    </div>

                    <div>
                    <BsTelephoneForwardFill />
                    <p>01-4425292 ext: 203</p>
                    </div>

                    <div>
                    <BsFillPhoneFill />
                    <p>9851053756</p>
                    </div>
                </div>
                </div>

            </div>

            <div className='officer'>
                <div className='branch'>
                    <h3>Head Office Naxal</h3>
                </div>
                
                <div className='paper'>
                <div className='detail-container'>
                    <img src={griev} alt="grievance-officer" />
                    <p className='officer-name'>Mr. Amit Shrestha</p>
                    <div>
                    <MdEmail />
                    <p>amit.shrestha@icfcbank.com</p>
                    </div>

                    <div>
                    <BsTelephoneForwardFill />
                    <p>01-4425292 ext: 203</p>
                    </div>

                    <div>
                    <BsFillPhoneFill />
                    <p>9851053756</p>
                    </div>
                </div>
                </div>

            </div>

            <div className='officer'>
                <div className='branch'>
                    <h3>Head Office Naxal</h3>
                </div>
                
                <div className='paper'>
                <div className='detail-container'>
                    <img src={griev} alt="grievance-officer" />
                    <p className='officer-name'>Mr. Amit Shrestha</p>
                    <div>
                    <MdEmail />
                    <p>amit.shrestha@icfcbank.com</p>
                    </div>

                    <div>
                    <BsTelephoneForwardFill />
                    <p>01-4425292 ext: 203</p>
                    </div>

                    <div>
                    <BsFillPhoneFill />
                    <p>9851053756</p>
                    </div>
                </div>
                </div>

            </div>

            <div className='officer'>
                <div className='branch'>
                    <h3>Head Office Naxal</h3>
                </div>
                
                <div className='paper'>
                <div className='detail-container'>
                    <img src={griev} alt="grievance-officer" />
                    <p className='officer-name'>Mr. Amit Shrestha</p>
                    <div>
                    <MdEmail />
                    <p>amit.shrestha@icfcbank.com</p>
                    </div>

                    <div>
                    <BsTelephoneForwardFill />
                    <p>01-4425292 ext: 203</p>
                    </div>

                    <div>
                    <BsFillPhoneFill />
                    <p>9851053756</p>
                    </div>
                </div>
                </div>

            </div>

            <div className='officer'>
                <div className='branch'>
                    <h3>Head Office Naxal</h3>
                </div>
                
                <div className='paper'>
                <div className='detail-container'>
                    <img src={griev} alt="grievance-officer" />
                    <p className='officer-name'>Mr. Amit Shrestha</p>
                    <div>
                    <MdEmail />
                    <p>amit.shrestha@icfcbank.com</p>
                    </div>

                    <div>
                    <BsTelephoneForwardFill />
                    <p>01-4425292 ext: 203</p>
                    </div>

                    <div>
                    <BsFillPhoneFill />
                    <p>9851053756</p>
                    </div>
                </div>
                </div>

            </div>

            <div className='officer'>
                <div className='branch'>
                    <h3>Head Office Naxal</h3>
                </div>
                
                <div className='paper'>
                <div className='detail-container'>
                    <img src={griev} alt="grievance-officer" />
                    <p className='officer-name'>Mr. Amit Shrestha</p>
                    <div>
                    <MdEmail />
                    <p>amit.shrestha@icfcbank.com</p>
                    </div>

                    <div>
                    <BsTelephoneForwardFill />
                    <p>01-4425292 ext: 203</p>
                    </div>

                    <div>
                    <BsFillPhoneFill />
                    <p>9851053756</p>
                    </div>
                    
                </div>
                </div>

            </div>

            <div className='officer'>
                <div className='branch'>
                    <h3>Head Office Naxal</h3>
                </div>
                
                <div className='paper'>
                <div className='detail-container'>
                    <img src={griev} alt="grievance-officer" />
                    <p className='officer-name'>Mr. Amit Shrestha</p>
                    <div>
                    <MdEmail />
                    <p>amit.shrestha@icfcbank.com</p>
                    </div>

                    <div>
                    <BsTelephoneForwardFill />
                    <p>01-4425292 ext: 203</p>
                    </div>

                    <div>
                    <BsFillPhoneFill />
                    <p>9851053756</p>
                    </div>
                </div>
                </div>

            </div> */}
      </div>
    </Bread>
  );
};

export default ItTeams;
