import React from 'react'
import {useNavigate, Link} from 'react-router-dom'
import TimeAgo from '../../common/TimeAgo';
import './notification.scss'

const Notification = ({stateChanger, data}) => {

    const navigate = useNavigate();

  return (
    <div className='notificationContainer'>
        <div className='listContainer'>

            {data?.map((i) =>
                    <div key={i.id} className='item'>
                        <h2 onClick={() => { stateChanger(false); navigate(`/notices/${i.slug}`); }}>{i.Title}</h2>
        
                        {/* <p>{i.created_on}</p> */}
                        <TimeAgo timestamp={i.created_on} />
        
                        <div className='underline' style={{ marginTop: '8px' }}></div>
                    </div>
        
        
            )}   
        
        </div>

        <div className='MoreFlexContainer'>
            <Link onClick={() => stateChanger(false)} to="/notices">View More</Link>
        </div>
    </div>
  )
}

export default Notification