import React,{useState, useEffect} from 'react'
import {IoIosArrowForward} from 'react-icons/io'
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useParams, useNavigate  } from 'react-router-dom';
import Bread from '../../common/Bread'
import './services.scss'
import Loader from '../../common/Loader';

const Services = () => {
  const breadcrumList = {
    image: '',
    title: 'Services',
    l1: 'Home',
    l2: 'service',
    l3: ''
  }

  const navigate = useNavigate();

  const {slug} = useParams()


  const [isActive, setIsActive] = useState(slug)

  useEffect(() => {
    setIsActive(slug)
  },[slug])


  const { isLoading, error, data, isFetching } = useQuery({
      queryKey: ["servicesData"],
      queryFn: () =>
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/service/all`)
          .then((res) => res.data),
    });

    if (isFetching) return(
      <Loader />
    );
  
    if (isLoading) return(
      <Loader />
    );
  
    if (error) return "An error has occurred: " + error.message;

  return (
    <Bread data={breadcrumList} >
    <div className='servicePageContainer'>
        <div className='contentContainer'>
        {data.items.map((i, index) => (
          <div 
          key={index}
          className='content'
          style={{ display: `${isActive == i.slug?'block':'none'}` }}
          >
            {/* <p 
                dangerouslySetInnerHTML={{ __html: i.Description }}
            ></p> */}
            <h2 className='content-title'># {i.Title}</h2>
              <div className='services-ck-content' dangerouslySetInnerHTML={{ __html: i.Description }}>

             </div>
          </div>
          ))}
        </div>  

        <div className='buttons'>
        <h2>Services</h2>
        {data.items.map((i, index) => (
          <div 
          key={i.slug}
          onClick={() => {navigate(`/services/${i.slug}`); window.scrollTo(0, 0);}}
          className='btn'
          style={{ color: `${isActive == i.slug?'#fff':'#0f2137'}`, backgroundColor: `${isActive == i.slug?'#003399':'#fff'}`,
        }
        }
          >
            <p>{i.Title}</p>
            <IoIosArrowForward className='icon' />
          </div>
        ))}

        </div>
    </div>
    </Bread>
  )
}

export default Services