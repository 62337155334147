import React,{useState, useEffect} from 'react'
import TextField from '@mui/material/TextField';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import {format, parseISO} from 'date-fns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from "@tanstack/react-query";
import Bread from '../../common/Bread'
import axios from "axios";
import './forex.scss'
import Loader from '../../common/Loader';

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#003399',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'DM Sans, sans-serif',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ADD8E6',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'DM Sans, sans-serif',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(173, 216, 230, 0.4)',
   
  },
  // hide last border
  '&:last-child td, &:last-child th': {
  //   border: 0,
  },
}));

const Forex = () => {
  const [value, setValue] = useState(null);
  const [isFetch, setIsFetch] = useState(false);
  const [locData, setLocData] = useState();

  // console.log("value =>", typeof value, value)

  const breadcrumList = {
    image: '',
    title: 'Forex',
    l1: 'Home',
    l2: 'Forex',
    l3: ''  
  }

  const handleChange = (newValue) => {
    // const d1 = format( newValue, 'yyyy-MM-dd');
    // setValue(d1);
    // console.log("newValue =>", typeof d1, d1)

    const AMonth = newValue.$M + 1

    const MDate = `${AMonth < 10 ? (0 + (AMonth).toString()) : ((AMonth).toString())}`
    const MDay = `${newValue.$D < 10 ? (0 + (newValue.$D).toString()) : ((newValue.$D).toString())}`

    const SDate = `${newValue.$y}-${MDate}-${MDay}`;
    setValue(SDate);
  };




  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["forexData", value, isFetch],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/forex/getForex/${value === null?(''):(value)}`)
        .then((res) => res.data),
  });

  useEffect(() => {
    if(data?.data){
      setLocData(data);      
    } else {
      setIsFetch(!isFetch);
    }
    return;
  }, [data])

  if (isFetching) return(
    <Loader />
  );

  if (isLoading) return(
    <Loader />
  );

  if (error) return "An error has occurred: " + error.message;

  if (!locData) return(
    <Loader />
  );

  return (
    <Bread data={breadcrumList} >
    <div className='forexContainer'>
      <div className='forexPagesHeaderFlexContainer'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
          {window.screen.width > 600 ? (
            <DesktopDatePicker
            label="Date"
            inputFormat="YYYY-MM-DD"
            value={value}
            onChange={handleChange}
            disableFuture={true}
            renderInput={(params) => <TextField {...params} />}
            className="mui-date"
            />
            ) : (
              <MobileDatePicker
              label="Date"
              inputFormat="YYYY-MM-DD"
              value={value}
              disableFuture={true}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
              className="mui-date"
              />
            )}
      </LocalizationProvider>

      <h3>Updated on: {data.status === "success" && data.data[0].published_on.substring(0, 10)}</h3>
      </div>

      {data.status === "success" ? (
      <div className='InterestPageTableContainer'>
      <TableContainer sx={{border: '1px solid #c2c2c2'}} component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom table">

            <TableHead>
                  <TableRow>
                  <StyledHeadTableCell sx={{ fontSize: '17px' }} align="center">S.No</StyledHeadTableCell>
                    <StyledHeadTableCell sx={{ fontSize: '17px' }} align="center">Country</StyledHeadTableCell>
                    <StyledHeadTableCell sx={{ fontSize: '17px' }} align="center">Unit</StyledHeadTableCell>
                    <StyledHeadTableCell sx={{ fontSize: '17px' }} align="center">Buy</StyledHeadTableCell>
                    <StyledHeadTableCell sx={{ fontSize: '17px' }} align="center">Sell</StyledHeadTableCell>
                  </TableRow>
                </TableHead>   

                <TableBody>
                {data.data[0]?.forex_values.map((item, index) => (
                    <StyledTableRow key={index} className='InterestPageTableRow'>
                    <StyledTableCell sx={{ width: '5%' }} className="InterestPageTableCell InterestPageTableDateText" align="center" component="th" scope="row">
                        {index+1}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '35%' }} className="InterestPageTableCell InterestPageTableDateText" align="center" component="th" scope="row">
                      <div className='flag-code-container'>
                      <div className={`currency-flag currency-flag-${item.iso3}`}></div>
                        <p>{item.iso3}</p>
                      </div>
                        
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '10%' }}  className="InterestPageTableCell InterestPageTableTitleText" align="center">{item.unit}</StyledTableCell>

                    <StyledTableCell sx={{ width: '25%' }}  className="InterestPageTableCell InterestPageTableTitleText" align="center">{item.buy}</StyledTableCell>

                    <StyledTableCell sx={{ width: '25%' }}  className="InterestPageTableCell InterestPageTableTitleText" align="center">{item.sell}</StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
      </div>
      ) : (
        <h3>No data</h3>
      )}

    </div>
    </Bread>
  )
}

export default Forex