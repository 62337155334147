import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import 'react-tabs/style/react-tabs.css';
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Bread from "../../common/Bread";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../common/Loader";
import axios from "axios";
import "./faq.scss";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const LocalData = [
  {
    id: 1,
    q: "hello",
    a: "world",
  },
  {
    id: 2,
    q: "namaste",
    a: "nepal",
  },
  {
    id: 3,
    q: "test",
    a: "test is test",
  },
];

const Faq = () => {
  const [expanded, setExpanded] = React.useState("0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const breadcrumList = {
    image: "",
    link: "",
    title: "Frequently Asked Questions",
    l1: "Home",
    l2: "FAQ's",
    l3: "",
  };

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["faqData"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/faq`)
        .then((res) => res.data),
  });

  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;

  return (
    <Bread data={breadcrumList}>
      <div className="faqPageMainContainer">
        <Tabs className="faqmain-tab" defaultIndex={1}>
          <TabList className="faqmainbox">
            {data?.items.map((value, index) => (
              <Tab key={index} className="faqbtns">
                {value.title}
              </Tab>
            ))}
          </TabList>

          <div className="FaqAcoordianContainer">
            {data?.items.map((value, index) => (
              <TabPanel key={value.id}>
                {value.faqs.length > 0 ? (
                  value.faqs.map((i, index) => (
                    <Accordion
                      key={i.id}
                      expanded={expanded === `${index}`}
                      onChange={handleChange(`${index}`)}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                      >
                        <Typography className="faqPageAccordianTitleText">
                          {i.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          className="faqPageAccordianAnsText"
                          dangerouslySetInnerHTML={{ __html: i.answer }}
                        ></Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <h2>No data</h2>
                )}
              </TabPanel>
            ))}
          </div>
        </Tabs>
      </div>
    </Bread>
  );
};

export default Faq;
