import React,{useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import './visitCard.scss'

const VisitCard = ({data}) => {
    const [isClose, setIsClose] = useState(false)
    const [visible, setVisible] = useState(false);

    useEffect(()=>{
      let visit_status = localStorage.getItem('visit_status');
      
      if(!visit_status){
        setVisible(true);
        localStorage.setItem('visit_status',1);
      }

    },[]);

  if(!visible) return null;

  return (
    <div className={isClose ? 'displayNone' : 'lastVisitContainer'}>
        <div className='content'>
            <p>Last visited {data.time.replace(/,/g,'')}</p>

            <Link to={data.pathname}>Go to Last Visited Page</Link>

            <div onClick={() => setIsClose(true)} className='btn'>
                <p>Close</p>
            </div>
        </div>
    </div>
  )
}

export default VisitCard