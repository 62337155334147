import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Bread from "../../common/Bread";
import { Blurhash } from "react-blurhash";
import { motion } from "framer-motion";
import "./teams.scss";
import Loader from "../../common/Loader";
import ItTeams from "./ItTeams";

const Teams = () => {
  const [title, setTitle] = useState("");
  const [imageLoading, setLoading] = useState(true);
  const { slug } = useParams();

  function titleSwitch() {
    switch (slug) {
      case "bod":
        {
          setTitle("Board Of Directors");
        }
        break;
      case "management-team":
        {
          setTitle("Management Team");
        }
        break;
      case "hod":
        {
          setTitle("Head Of Department");
        }
        break;
      case "regional-head":
        {
          setTitle("Regional Head");
        }
        break;
      case "company-secretary":
        {
          setTitle("Company Secretary");
        }
        break;
      default:
        {
          setTitle("Teams");
        }
        break;
    }
  }

  var breadMemo = useMemo(titleSwitch, [slug]);

  const onLoaded = () => {
    setLoading(false);
  };

  const breadcrumList = {
    image: "",
    title: title,
    l1: "Home",
    l2: "Teams",
    l3: "",
  };

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["teamData", slug],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/team/all/${slug}`)
        .then((res) => res.data),
  });

  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;

  if (slug === "it-officers") return <ItTeams data={data} />;

  const blockTrue = data.items.en.find((obj) => {
    return obj.is_block === "Yes";
  });

  // const blockFalse = data.items.en.filter(obj => {
  //   return obj.is_block !== 'Yes'
  // })

  // console.log("block =>", typeof blockFalse, blockFalse)

  return (
    <Bread data={breadcrumList}>
      {blockTrue && (
        <div className="teams-block">
          {/* {data.items.en.find(obj => {
      return obj.is_block === 'Yes'
    }).map((i, index) => ( */}

          <div className="teams-block-profile-container">
            <div className="blurhash-wrapper">
              <Blurhash
                hash={blockTrue.blur}
                width={250}
                height={340}
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
              <motion.img
                initial={{ opacity: 0 }}
                animate={{
                  opacity: imageLoading ? 0 : 1,
                }}
                transition={{ opacity: { delay: 0.5, duration: 0.4 } }}
                loading="lazy"
                onLoad={onLoaded}
                className="image"
                src={blockTrue.featured_image}
                alt={blockTrue.designation_name}
                width={250}
                height={340}
              />
            </div>
          </div>
          <div className="chairmain-details">
            <h3>{blockTrue.name}</h3>
            <h4>{blockTrue.designation_name}</h4>
          </div>

          {/* ))} */}
        </div>
      )}

      <div
        className="teamsPageMainContainer"
        style={{
          gridTemplateColumns: `${
            window.innerWidth > 800 &&
            (data.items.en.filter((obj) => {
              return obj.is_block !== "Yes";
            }).length === 1
              ? "repeat(1, minmax(0, 1fr))"
              : data.items.en.filter((obj) => {
                  return obj.is_block !== "Yes";
                }).length === 2
              ? "repeat(2, minmax(0, 1fr))"
              : "")
          }`,
        }}
      >
        {data.items.en
          .filter((obj) => {
            return obj.is_block !== "Yes";
          })

          .map((i, index) => (
            <div key={index} className="teams-profile-container">
              <div className="team-image-box">
                <div className="blurhash-wrapper">
                  <Blurhash
                    hash={i.blur}
                    width={240}
                    height={330}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                  />
                  <motion.img
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: imageLoading ? 0 : 1,
                    }}
                    transition={{ opacity: { delay: 0.5, duration: 0.4 } }}
                    loading="lazy"
                    onLoad={onLoaded}
                    className="image"
                    src={i.featured_image}
                    alt={i.designation_name}
                    width={240}
                    height={330}
                  />
                </div>
              </div>
              <h3>{i.name}</h3>
              {slug === "hod" ? (
                <h4>{i.department_name}</h4>
              ) : (
                <h4>{i.designation_name}</h4>
              )}
              {slug === "regional-head" ? <h4>{i.sub_designation}</h4> : ""}
              {slug === "company-secretary" ? <h4>{i.contact}</h4> : ""}
              {slug === "company-secretary" ? <h4>{i.email}</h4> : ""}
            </div>
          ))}
      </div>
    </Bread>
  );
};

export default Teams;
