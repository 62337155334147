import React from "react";
import { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import { Grid, ListItem } from "@mui/material";
import { Pagination } from "@mui/material";
import Bread from "../../common/Bread";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import AxiosClass from "../../common/AxiosClass";
import { LangContext } from "../../context/LangContext";
import axios from "axios";
import TimeAgo from "../../common/TimeAgo";
import Loader from "../../common/Loader";
import "./index.scss";
import coverImage from "../../assets/achivement/achivement.png";
const Achievements = () => {
  const { slug } = useParams();
  const items = useRef([]);
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(
        `https://admin.icfcbank.com/api/Achievement/all/`
      );
      setData(response.data.items);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }
  if (loading) return "....";
  if (error) return "error";

  if (error) return "An error has occurred: " + error.message;

  let breadcrumList;

  if (nep) {
    items.current = data?.np;
    breadcrumList = {
      image: "",
      link: "/achievements",
      title: `उपलब्धि र माइलस्टोनहरू`,
      l1: "होम",
      l2: "उपलब्धि र माइलस्टोनहरू",
    };
  } else {
    items.current = data?.en;
    breadcrumList = {
      image: "",
      link: "/achievements",
      title: `Achievements and Milestones`,
      l1: "Home",
      l2: "Achievements",
    };
  }
  return (
    <>
      <Bread data={breadcrumList}>
        <div className="Achivemnts">
          {items?.current?.map((achievements, index) => {
            console.log(achievements);
            return (
              <>
                <div className="AchivItems" key={index}>
                  <div className="coverimg">
                    <img src={coverImage} alt="Coer Image" />
                  </div>
                  <div className="Titless">
                    <h1>{achievements.Title}</h1>
                    <Link
                      class="EXPBTN"
                      to={`/achievement/details/${achievements.slug}`}
                    >
                      Explore
                    </Link>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </Bread>
    </>
  );
};
export default Achievements;
