import React, { useRef } from "react";
import Bread from "../../common/Bread";
import TimeAgo from "../../common/TimeAgo";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { LangContext } from "../../context/LangContext";
import { useQuery } from "@tanstack/react-query";
import AxiosClass from "../../common/AxiosClass";

import Loader from "../../common/Loader";

const Detail = () => {
  const item = useRef({});

  const lang = React.useContext(LangContext);
  const nep = lang.state.np;

  const { slug } = useParams();

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["newsDataSingle"],
    queryFn: () =>
      AxiosClass.getApi(`/news/detail/${slug}`).then((res) => res.data),
  });

  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;

  // console.log(data);

  let breadcrumList;

  if (nep) {
    item.current = data.detail.np;

    breadcrumList = {
      image: "",
      link: "/news",
      title: `${item.current.Title}`,
      l1: "होम",
      l2: "न्युज",
      l3: `${item.current.Title}`,
    };
  } else {
    item.current = data.detail.en;

    breadcrumList = {
      image: "",
      link: "/news",
      title: `${item.current.Title}`,
      l1: "Home",
      l2: "News",
      l3: `${item.current.Title}`,
    };
  }

  // const breadcrumList = {
  //     image: '',
  //     link: '/news',
  //     title: 'News For Test',
  //     l1: 'Home',
  //     l2: 'News',
  //     l3: slug
  // }

  return (
    <Bread data={breadcrumList}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={12} sm={12} md={12} key={1}>
          <div className="detail_content">
            {item.current.DocPath && (
              <object
                data={item.current.DocPath}
                width="100%"
                height="1500px"
              ></object>
            )}

            {/* <div className="image_detail">
              {item.current.CoverImage ? (
                <img src={item.current.CoverImage} alt="gg" />
              ) : (
                ""
              )}
            </div>
            <div className="detail_content-child">
              
              <TimeAgo timestamp={item.current.created_on} />

              {item.current.Description && (
                <div
                  dangerouslySetInnerHTML={{ __html: item.current.Description }}
                  className="services-ck-content marginTop"
                ></div>
              )}

              {item.current.DocPath && (
                <a className="external-button" href={item.current.DocPath} target="_blank">
                  <p>View File</p>
                </a>
              )}
            </div> */}
          </div>
        </Grid>
      </Grid>
    </Bread>
  );
};

export default Detail;
