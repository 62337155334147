import React from "react";
import { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import { Grid, ListItem } from "@mui/material";
import { Pagination } from "@mui/material";
import Bread from "../../common/Bread";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import AxiosClass from "../../common/AxiosClass";
import { LangContext } from "../../context/LangContext";
import axios from "axios";
import TimeAgo from "../../common/TimeAgo";
import Loader from "../../common/Loader";
import "./index.scss";
const Achievements_details = () => {
  const { slug } = useParams();
  const items = useRef([]);
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(
        `https://admin.icfcbank.com/api/Achievement/detail/${slug}`
      );
      setData(response.data.detail);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }
  if (loading) return "....";
  if (error) return "error";

  if (error) return "An error has occurred: " + error.message;

  let breadcrumList;

  if (nep) {
    items.current = data?.np;
    breadcrumList = {
      image: "",
      link: "/achievements",
      title: `${items.current.Title}`,
      l1: "होम",
      l2: "उपलब्धि र माइलस्टोनहरू",
      l3: `${items.current.Title}`,
    };
  } else {
    items.current = data?.en;
    breadcrumList = {
      image: "",
      link: "/achievements",
      title: `${items.current.Title}`,
      l1: "Home",
      l2: "Achievements",
      l3: `${items.current.Title}`,
    };
  }
  return (
    <>
      <Bread data={breadcrumList}>
        <div className="Description">
          <div
            className="CKContentBox"
            dangerouslySetInnerHTML={{
              __html: items?.current?.Description,
            }}
          ></div>
        </div>
        <div className="Achivemnts_details">
          {items?.current?.images?.map((imagess, index) => {
            return (
              <>
                <div className="detailsImage" key={index}>
                  <img src={imagess} alt="Images" />
                </div>
              </>
            );
          })}
        </div>
      </Bread>
    </>
  );
};
export default Achievements_details;
