import React from 'react'
import { useNavigate } from 'react-router-dom'
// import { useAnimation, motion } from 'framer-motion';
// import { useInView } from 'react-intersection-observer';
import group from '../../assets/csr/group.png'
import './csr.scss'

const CSR = () => {
  // const controls = useAnimation();
  // const [ref, inView] = useInView();

  // React.useEffect(() => {
  //   if(inView) {
  //     controls.start("visible");
  //   }
  // }, [controls, inView]);

  const navigate = useNavigate();

  return (
    // <div className='csrMessageSectionMainBgWrapperMain'>
    // <div className='csrMessageSectionMainBgWrapper'>
    <div className='csrSectionContainer'>
        <div className='contentContainer'>
            <h2>Our Stories</h2>
            <p className='black-text'>Our contribution</p>
            <p className='blue-text'>towards community</p>

            <div 
            className="button"
            // onClick={() => navigate('/csr')}
            onClick={() => window.scrollTo({
              top: 600,
              // left: 100,
              behavior: "smooth",
            })}
            >  
                Explore Our Stories
            </div> 
        </div>

        <div className='rightFlexContainer'>
        <img src={group} alt="csr-image" />
        {/* <div className='twoFlexContainer'>
            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ficfcbank%2F&tabs=timeline&width=312&height=460&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="312" height="460" style={{border:"none",overflow:"hidden",borderRadius:"8px"}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" />
        </div> */}
        </div>

    </div>
    // </div>
    // </div>


  )
}

export default CSR