import React from "react";
import { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import { Grid, ListItem } from "@mui/material";
import { Pagination } from "@mui/material";
import Bread from "../../common/Bread";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import AxiosClass from "../../common/AxiosClass";
import { LangContext } from "../../context/LangContext";
import axios from "axios";
import TimeAgo from "../../common/TimeAgo";
import CSR from "../../components/CSR";
import Loader from "../../common/Loader";

const CSRItems = () => {
  const [isPage, setIsPage] = useState(0);
  const { slug } = useParams();
  const items = useRef([]);
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(
        `https://admin.icfcbank.com/api/csr/all/${slug}`
      );
      setData(response.data.items);
      console.log("ALL", response.data.items);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }
  if (loading) return "....";
  if (error) return "error";

  if (error) return "An error has occurred: " + error.message;

  let breadcrumList;

  if (nep) {
    items.current = data?.np;

    breadcrumList = {
      image: "",
      link: "/csr",
      title: `${data?.en[0]?.csr_type_name}`,
      l1: "होम",
      l2: "कर्पोरेट सामाजिक उत्तरदायित्व",
      l3: `${data?.en[0]?.csr_type_name}`,
    };
  } else {
    items.current = data?.en;

    breadcrumList = {
      image: "",
      link: "/csr",
      title: `${data?.en[0]?.csr_type_name}`,
      l1: "Home",
      l2: "CSR",
      l3: `${data?.en[0]?.csr_type_name}`,
    };
  }
  console.log("items.current", items.current);

  return (
    <>
      {items.current.length == 0 ? (
        <div className="NoData">data not available</div>
      ) : (
        <Bread data={breadcrumList}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {items?.current?.map((item, index) => {
              return (
                <>
                  {item.csr_type_id == slug ? (
                    <Grid item xs={12} sm={4} md={4} key={index}>
                      <div className="box-item-news">
                        <div className="img-news">
                          <img src={item?.DocPath} alt={item?.Title} />
                        </div>
                        <div className="content-news">
                          {/* <TimeAgo timestamp={item?.created_on} /> */}
                          <h1>
                            <Link to={`/csr/lists/items/details/${item.slug}`}>
                              {item?.Title.substring(0, 100)}
                            </Link>
                          </h1>
                        </div>
                      </div>
                    </Grid>
                  ) : (
                    "NO Data"
                  )}
                </>
              );
            })}
          </Grid>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              {data?.total >= data?.per_page && (
                <Pagination
                  style={{ marginTop: "24px" }}
                  className="paginationContainer"
                  count={Math.ceil(data?.total / data?.per_page)}
                  color="primary"
                  size="large"
                  page={isPage}
                  onChange={(e, value) => setIsPage(value)}
                />
              )}
            </Grid>
          </Grid>
        </Bread>
      )}
    </>
  );
};

export default CSRItems;
