import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BsTelephoneForwardFill, BsFillPhoneFill } from "react-icons/bs";
import Bread from "../../common/Bread";
import { MdEmail } from "react-icons/md";
import "./style.scss";
const Officers = () => {
  const [title, setTitle] = useState("Grievance Officers");
  const { slug } = useParams();
  const [Data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getSampleApiData();
  }, [slug]);
  async function getSampleApiData() {
    try {
      const response = await axios(
        `https://admin.icfcbank.com/api/officers/officer/${slug ? slug : ""}`
      );
      setTitle(
        `${
          response.data
            ? response.data.detail
              ? response.data.detail.type
              : ""
            : {}
        } Officers`
      );
      setData(response.data.detail);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }

  const breadcrumList = {
    image: "",
    title: title,
    l1: "Home",
    l2: "Officers",
    l3: "",
  };

  if (loading) return "...";
  if (error) return "Error!";
  // console.log("Name---------", Data, loading);
  return (
    <Bread data={breadcrumList}>
      <div className="officerss">
        <div className="branch"></div>
        <div className="paper">
          <div className="detail-container ">
            <img
              src={Data ? Data.featured_image : ""}
              alt={Data ? Data.slug : ""}
            />
            <p className="officer-name">{Data ? Data.name : ""}</p>
            <div>
              <MdEmail />
              <p>{Data ? Data.email : ""}</p>
            </div>

            <div>
              <BsTelephoneForwardFill />
              <p>{Data ? Data.contact : ""}</p>
            </div>

            <div>
              <BsFillPhoneFill />
              <p>{Data ? Data.mobile : ""}</p>
            </div>
          </div>
        </div>
      </div>
    </Bread>
  );
};
export default Officers;
