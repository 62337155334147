import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { LangContext } from "../../context/LangContext";
import Bread from "../../common/Bread";
import "./cat.css";
const CSRSUbCat = () => {
  const item = useRef({});
  const [isPage, setIsPage] = useState(1);
  const items = useRef([]);
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getApiData();
  }, [slug]);
  async function getApiData() {
    try {
      const response = await axios(
        `https://admin.icfcbank.com/api/csr/csr_sub/${slug}`
      );
      setData(response.data.detail);
      console.log("SUB CAT", response.data.detail);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }
  if (loading) return "....";
  if (error) return "error";
  let breadcrumList;

  if (nep) {
    items.current = data.np[0];
    breadcrumList = {
      image: "",
      link: "/csr",
      title: `${data?.en[0].categoryName}`,
      l1: "होम",
      l2: "कर्पोरेट सामाजिक उत्तरदायित्व",
      l3: `${data?.en[0].categoryName}`,
    };
  } else {
    items.current = data?.en[0];
    breadcrumList = {
      image: "",
      link: "/csr",
      title: `${data?.en[0]?.categoryName}`,
      l1: "Home",
      l2: "CSR",
      l3: `${data?.en[0]?.categoryName}`,
    };
  }
  return (
    <>
      <Bread data={breadcrumList}>
        <div className="csr_cat_box">
          {!nep
            ? data?.en?.map((i) => {
                return (
                  <>
                    {i.parent_id == slug ? (
                      <div className="cat_items" key={i.id}>
                        <div className="cat_image">
                          <img src={i.DocPath ? i.DocPath : ""} />
                        </div>
                        <div className="cat_title">
                          <h1>{i.Title ? i.Title : ""}</h1>
                          <a
                            className="CSRBTN"
                            href={`/csr/lists/items/${i.id}`}
                          >
                            Explore
                          </a>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              })
            : data?.np?.map((i) => {
                return (
                  <>
                    {i.parent_id == slug ? (
                      <div className="cat_items">
                        <div className="cat_image">
                          <img src={i.DocPath ? i.DocPath : ""} />
                        </div>
                        <div className="cat_title">
                          <h1>{i.Title ? i.Title : ""}</h1>
                          <a className="CSRBTN" href={`/csr/items/${i.id}`}>
                            Explore
                          </a>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
        </div>
      </Bread>
    </>
  );
};
export default CSRSUbCat;
