import React from 'react'
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import './companyProfile.scss'
import Loader from '../../common/Loader';

const CompanyProfile = () => {
    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ["companyProfileData"],
        queryFn: () =>
          axios
            .get(`${process.env.REACT_APP_BASE_URL}/page/detail/company-profile`)
            .then((res) => res.data),
      });
  
      if (isFetching) return(
        <Loader />
      );
    
      if (isLoading) return(
        <Loader />
      );
    
      if (error) return "An error has occurred: " + error.message;

  return (
    <div>
        <div style={{textAlign: 'justify', hyphens: 'auto'}} className='companyProfileCKContainer' dangerouslySetInnerHTML={{ __html: data.detail.en.Description }}>
        
        </div>
    </div>
  )
}

export default CompanyProfile