import { BsGlobe } from "react-icons/bs";
import { MdOutlineMail } from "react-icons/md";
import meroshare from "../../assets/navbar/meroshare.png";
import connectips from "../../assets/navbar/connectips.png";
import "./onlineDropdown.scss";

const OnlineDropdown = () => {
  return (
    <div className="loginDropCompContainer onlineDropdown">
      <div className="item">
        <a href="https://online.icfcbank.com/" target="_blank">
          {/* <BsGlobe size={20} /> */}
          <p>Online Account Opening</p>
        </a>
        <div className="underline"></div>

        <a
          href="https://online.icfcbank.com/status/verification"
          target="_blank"
        >
          {/* <MdOutlineMail size={22} /> */}
          <p>Online Account Opening (Tracking)</p>
        </a>
        <div className="underline"></div>

        <a
          href="https://online.icfcbank.com/documents/verification"
          target="_blank"
        >
          <p>Online Document Verification</p>
        </a>
      </div>
    </div>
  );
};

export default OnlineDropdown;
